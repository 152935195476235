import { Host } from '@/models/Host';
import { Interface } from '@/models/Interface';
import { AppRoutes } from '@/routes';
import { HostsService } from '@/services/HostsService';
import { useStore } from '@/store/store';
import { getNodeConnectivityStatus } from '@/utils/NodeUtils';
import { extractErrorMsg } from '@/utils/ServiceUtils';
import { ExclamationCircleFilled, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Dropdown,
  Form,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Skeleton,
  Table,
  TableColumnsType,
  Tabs,
  TabsProps,
  Tag,
  theme,
  Typography,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageProps } from '../../models/Page';
import './NetworkHostDetailsPage.scss';
import { getHostRoute, getNetworkRoute, useQuery } from '@/utils/RouteUtils';
import { Node } from '@/models/Node';
import { NodeConnectivityStatus } from '@/models/NodeConnectivityStatus';
import { DATE_TIME_FORMAT } from '@/constants/AppConstants';
import UpdateNodeModal from '@/components/modals/update-node-modal/UpdateNodeModal';
import { NodesService } from '@/services/NodesService';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export default function NetworkHostDetailsPage(props: PageProps) {
  const { t } = useTranslation();
  const { hostId, networkId } = useParams<{ hostId: string; networkId: string }>();
  const navigate = useNavigate();
  const store = useStore();
  const [notify, notifyCtx] = notification.useNotification();
  const { token: themeToken } = theme.useToken();
  const queryParams = useQuery();

  const storeDeleteNode = store.deleteNode;
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingNode, setIsEditingNode] = useState(false);
  const [host, setHost] = useState<Host | null>(null);
  const [node, setNode] = useState<Node | null>(null);
  const [searchText, setSearchText] = useState('');

  const network = store.networks.find((n) => n.netid === networkId);

  const interfacesTableCols: TableColumnsType<Interface> = useMemo(
    () => [
      {
        title: t('3WHyqtcOa34N5vK0hJtLg'),
        dataIndex: 'name',
        render: (name) => {
          return (
            <Typography.Text>
              {name} {name === host?.defaultinterface ? <Tag>{t('9BfFao234Ivv8DGvIVfS')}</Tag> : <></>}
            </Typography.Text>
          );
        },
      },
      {
        title: t('p1nHzRj8xukU5cD23p2Ax'),
        dataIndex: 'addressString',
      },
    ],
    [host?.defaultinterface],
  );

  const onUpdateNode = useCallback(() => {
    setIsEditingNode(false);
  }, []);

  const getHostHealth = useCallback(() => {
    const nodeHealth: NodeConnectivityStatus = node ? getNodeConnectivityStatus(node) : 'unknown';

    switch (nodeHealth) {
      default:
        return <Tag>&#9679; {t('vv6Qpd3wDr3Gyi4Rh8p18')}</Tag>;
      case 'error':
        return <Tag color="error">&#9679; {t('yEWqSeJXmpNEmbkjuKxm')}</Tag>;
      case 'warning':
        return <Tag color="warning">&#9679; {t('xGnH4kkvY9Cyz0M6xpK8')}</Tag>;
      case 'healthy':
        return <Tag color="success">&#9679; {t('hVVpxosa9q0qlLzRep6vT')}</Tag>;
    }
  }, [node]);

  const loadDetails = useCallback(() => {
    setIsLoading(true);
    if (!networkId) {
      navigate(AppRoutes.NETWORKS_ROUTE);
      return;
    }
    if (!hostId) {
      navigate(getNetworkRoute(networkId));
      return;
    }
    // load from store
    const host = store.hosts.find((h) => h.id === hostId);
    const node = store.nodes.find((n) => n.network === networkId && n.hostid === hostId);
    if (!host || !node) {
      notify.error({ message: t('waDqpHp0Jhlr9aJlTzs5', { hostId }) });
      navigate(getNetworkRoute(networkId));
      return;
    }
    setHost(host);
    setNode(node);

    setIsLoading(false);
  }, [networkId, hostId, store.hosts, store.nodes, navigate, notify]);

  const onHostRemove = useCallback(
    async (forceDelete: boolean) => {
      try {
        if (!hostId || !node || !networkId) {
          throw new Error(t('ty1ly7q2lW9xf1UqOmc'));
        }
        await NodesService.deleteNode(node?.id, networkId, forceDelete);
        if (forceDelete) {
          storeDeleteNode(node.id);
        }
        notify.success({ message: t('rnRa1w43xyYrQ25TzYvT', { hostName: hostId }) });
        navigate(getNetworkRoute(networkId));
      } catch (err) {
        notify.error({
          message: t('pa_8nPhP4UeinY3TbxSmU'),
          description: extractErrorMsg(err as any),
        });
      }
    },
    [hostId, node, networkId, notify, storeDeleteNode, navigate],
  );

  const onHostToggleConnectivity = useCallback(
    async (newStatus: boolean) => {
      try {
        if (!hostId || !node || !networkId) {
          throw new Error(t('ty1ly7q2lW9xf1UqOmc'));
        }
        const updatedNode = (await NodesService.updateNode(node.id, networkId, { ...node, connected: newStatus })).data;
        store.updateNode(node.id, updatedNode);
        notify.success({
          message: t('jtbVhrqkezWwl3Fpg9Ost', {
            status: newStatus ? t('kvNoDLic_7HsMhUYcOzk') : t('lN4vjPi4q_531Eigvixwb'),
          }),
          description: t('qHnvWcXvmDaGvAxBoAxa', {
            status: newStatus ? t('adyjtThxktgct62AdWsIy') : t('tseUaUrCd9Uy7P8kov7Ki'),
            network: networkId,
          }),
        });
      } catch (err) {
        notify.error({
          // message: `Failed to ${newStatus ? 'connect' : 'disconnect'} host ${newStatus ? 'to' : 'from'} network`,
          message: t('3oPjsdKdtq3rStfcsm2xQ', {
            operation: newStatus ? t('r6bJ80QtWipDxIiPikss6') : t('aReLuT4rQflwFlUwmpoIq'),
            direction: newStatus ? t('aVyDoUwFM47yVCkEn64v') : t('vLry8jxhVrHxAApKaqOjJ'),
          }),
          description: extractErrorMsg(err as any),
        });
      }
    },
    [hostId, node, networkId, notify, store],
  );

  const promptConfirmDisconnect = () => {
    Modal.confirm({
      title: t('qq2rhksNsGavmXdbAAiCf', {
        operation: node?.connected ? t('aReLuT4rQflwFlUwmpoIq') : t('r6bJ80QtWipDxIiPikss6'),
        hostName: host?.name,
        direction: node?.connected ? t('vLry8jxhVrHxAApKaqOjJ') : t('aVyDoUwFM47yVCkEn64v'),
        network: networkId,
      }),
      icon: <ExclamationCircleFilled />,
      onOk() {
        onHostToggleConnectivity(!node?.connected);
      },
    });
  };

  const promptConfirmRemove = () => {
    let forceDelete = false;

    Modal.confirm({
      title: t('kvr4iDnjQd27UGuNqp69', { hostName: host?.name, network: networkId }),
      content: (
        <>
          <Row>
            <Col xs={24}>
              <Form.Item
                htmlFor="force-delete"
                label="Force delete"
                valuePropName="checked"
                style={{ marginBottom: '0px' }}
              >
                <Checkbox
                  id="force-delete"
                  onChange={(e) => {
                    forceDelete = e.target.checked;
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
      icon: <ExclamationCircleFilled />,
      onOk() {
        onHostRemove(forceDelete);
      },
    });
  };

  const refreshHostKeys = useCallback(() => {
    if (!hostId) return;
    Modal.confirm({
      title: t('eUKw_5gCe0A65Xi7HoXmA'),
      content: t('n2H4GtoNqMvX6o0ffQmj6'),
      onOk: async () => {
        try {
          await HostsService.refreshHostKeys(hostId);
          notify.success({
            message: t('ptMmb9B_1D23NrRcgBfV'),
            description: t('grRhSlwPy0sutUyDvZDeU'),
          });
        } catch (err) {
          notify.error({
            message: t('q0LfKtB1ZaXZo8YwxqMm'),
            description: extractErrorMsg(err as any),
          });
        }
      },
    });
  }, [notify, hostId]);

  const getOverviewContent = useCallback(() => {
    if (!host) return <Skeleton active />;
    return (
      <div
        className=""
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexFlow: 'column nowrap',
          // backgroundColor: 'black',
        }}
      >
        <Card style={{ width: '50%', marginTop: '2rem' }}>
          <Typography.Title level={5} style={{ marginTop: '0rem' }}>
            {t('ei9ouH7JcA28ghMjvEuy')}
          </Typography.Title>

          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="network-host-details_network"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('rqA0WMisHumzthfZs4_7I')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{node?.network ?? ''}</Typography.Text>
            </Col>
          </Row>

          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="network-host-details_address"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('4XnrVeasDVxXy2OSm7lx9')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{node?.address ?? ''}</Typography.Text>
            </Col>
          </Row>

          {network && network.isipv6 && (
            <Row
              style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
              data-nmui-intercom="network-host-details_address6"
            >
              <Col xs={12}>
                <Typography.Text disabled>{t('zNyyau58tDn6bdDkyaPoV')}</Typography.Text>
              </Col>
              <Col xs={12}>
                <Typography.Text>{node?.address6 ?? ''}</Typography.Text>
              </Col>
            </Row>
          )}

          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="network-host-details_localaddress"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('pTwXnKCui0pBsze2dZvN2')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{node?.localaddress ?? ''}</Typography.Text>
            </Col>
          </Row>

          {node?.isegressgateway && (
            <Row
              style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
              data-nmui-intercom="network-host-details_egressgatewayranges"
            >
              <Col xs={12}>
                <Typography.Text disabled>{t('auIl9V5gBMgytQh8nXiH')}</Typography.Text>
              </Col>
              <Col xs={12}>
                <Typography.Text>{node?.egressgatewayranges?.join(', ') ?? ''}</Typography.Text>
              </Col>
            </Row>
          )}

          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="network-host-details_connected"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('f4xGuTeTHlpNscTyohC')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>
                {node?.connected ? t('wns6iOjgjBoyQCwV0T8A') : t('6mlDqPzLSztraHwU7qOlk')}
              </Typography.Text>
            </Col>
          </Row>

          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="network-host-details_persistentkeepalive"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('g43DqjhLgAnIb39peOsl')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{node?.persistentkeepalive ?? ''}</Typography.Text>
            </Col>
          </Row>

          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="network-host-details_defaultacl"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('8cuLdlnFhCj4TKw1yScqm')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{node?.defaultacl ?? ''}</Typography.Text>
            </Col>
          </Row>

          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="network-host-details_dnson"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('2uMUtN1WfMcJ6X0Goktsy')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{node?.dnson ? t('wns6iOjgjBoyQCwV0T8A') : t('6mlDqPzLSztraHwU7qOlk')}</Typography.Text>
            </Col>
          </Row>

          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="network-host-details_lastcheckin"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('wOd5WOcSbBw7rFp0ks9W')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{dayjs((node?.lastcheckin ?? 0) * 1000).format(DATE_TIME_FORMAT) ?? ''}</Typography.Text>
            </Col>
          </Row>

          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="network-host-details_expdatetime"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('2WixevMk89kTfixUf0X0A')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{dayjs((node?.expdatetime ?? 0) * 1000).format(DATE_TIME_FORMAT) ?? ''}</Typography.Text>
            </Col>
          </Row>

          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="network-host-details_lastmodified"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('ao7SvTahY3kgVtWz7EnmB')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>
                {dayjs((node?.lastmodified ?? 0) * 1000).format(DATE_TIME_FORMAT) ?? ''}
              </Typography.Text>
            </Col>
          </Row>
        </Card>

        <Card style={{ width: '50%', marginTop: '2rem' }}>
          <Collapse ghost size="small">
            <Collapse.Panel
              key="details"
              header={
                <Typography.Title level={5} style={{ marginTop: '0rem' }}>
                  {t('pdIlOm72Vx0T0ToAoLfW2')}
                </Typography.Title>
              }
            >
              <Row
                style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
                data-nmui-intercom="network-host-details_hostid"
              >
                <Col xs={12}>
                  <Typography.Text disabled>{t('wqDzwbG2upD2a7QaQvni')}</Typography.Text>
                </Col>
                <Col xs={12}>
                  <Typography.Text>{host.id}</Typography.Text>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
                data-nmui-intercom="network-host-details_hostendpoint"
              >
                <Col xs={12}>
                  <Typography.Text disabled>{t('31T4VGqDDgp5Vy56Sf3mI')}</Typography.Text>
                </Col>
                <Col xs={12}>
                  <Typography.Text>{host.endpointip}</Typography.Text>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
                data-nmui-intercom="network-host-details_hostlistenport"
              >
                <Col xs={12}>
                  <Typography.Text disabled>{t('jktoRtH3nMfw9egqouHl')}</Typography.Text>
                </Col>
                <Col xs={12}>
                  <Typography.Text>{host.listenport}</Typography.Text>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
                data-nmui-intercom="network-host-details_hostmacaddress"
              >
                <Col xs={12}>
                  <Typography.Text disabled>{t('dv8Z8VAo0McrqSpU6TCoO')}</Typography.Text>
                </Col>
                <Col xs={12}>
                  <Typography.Text>{host.macaddress}</Typography.Text>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
                data-nmui-intercom="network-host-details_hostmtu"
              >
                <Col xs={12}>
                  <Typography.Text disabled>{t('c2kSJejRVktLokw67PRv')}</Typography.Text>
                </Col>
                <Col xs={12}>
                  <Typography.Text>{host.mtu}</Typography.Text>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
                data-nmui-intercom="network-host-details_hostpublickey"
              >
                <Col xs={12}>
                  <Typography.Text disabled>{t('nrw6eqACmLurlzLeH8L5h')}</Typography.Text>
                </Col>
                <Col xs={12}>
                  <Typography.Text>{host.publickey}</Typography.Text>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
                data-nmui-intercom="network-host-details_hostos"
              >
                <Col xs={12}>
                  <Typography.Text disabled>{t('teygqjva5yoip6w9Vjb2d')}</Typography.Text>
                </Col>
                <Col xs={12}>
                  <Typography.Text>{host.os}</Typography.Text>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
                data-nmui-intercom="network-host-details_hostversion"
              >
                <Col xs={12}>
                  <Typography.Text disabled>{t('lIZnT8On5DjilQhJL')}</Typography.Text>
                </Col>
                <Col xs={12}>
                  <Typography.Text>{host.version}</Typography.Text>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
                data-nmui-intercom="network-host-details_hostverbosity"
              >
                <Col xs={12}>
                  <Typography.Text disabled>{t('wZlODGwki8mzpFcvNy81')}</Typography.Text>
                </Col>
                <Col xs={12}>
                  <Typography.Text>{host.verbosity}</Typography.Text>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
                data-nmui-intercom="network-host-details_hostdefaultinterface"
              >
                <Col xs={12}>
                  <Typography.Text disabled>{t('9OnAl8wGroVprAuRy1zet')}</Typography.Text>
                </Col>
                <Col xs={12}>
                  <Typography.Text>{host.defaultinterface}</Typography.Text>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
                data-nmui-intercom="network-host-details_hostisdefault"
              >
                <Col xs={12}>
                  <Typography.Text disabled>{t('frZp4cX5H9hbsjSvdaf9M')}</Typography.Text>
                </Col>
                <Col xs={12}>
                  <Typography.Text>
                    {host.isdefault ? t('wns6iOjgjBoyQCwV0T8A') : t('6mlDqPzLSztraHwU7qOlk')}
                  </Typography.Text>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
                data-nmui-intercom="network-host-details_hostisstatic"
              >
                <Col xs={12}>
                  <Typography.Text disabled>{t('uKb52eKpVaAz56eWmqcL5')}</Typography.Text>
                </Col>
                <Col xs={12}>
                  <Typography.Text>
                    {host.isstatic ? t('wns6iOjgjBoyQCwV0T8A') : t('6mlDqPzLSztraHwU7qOlk')}
                  </Typography.Text>
                </Col>
              </Row>
              <Row
                style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
                data-nmui-intercom="network-host-details_hostdebug"
              >
                <Col xs={12}>
                  <Typography.Text disabled>{t('vClR7hI7gGhePJamGxGt')}</Typography.Text>
                </Col>
                <Col xs={12}>
                  <Typography.Text>
                    {host.debug ? t('wns6iOjgjBoyQCwV0T8A') : t('6mlDqPzLSztraHwU7qOlk')}
                  </Typography.Text>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        </Card>
      </div>
    );
  }, [host, network, node, themeToken.colorBorder]);

  const getNetworkInterfacesContent = useCallback(() => {
    return (
      <>
        <Row>
          <Col xs={12} md={8}>
            <Input
              size="large"
              placeholder={t('lxdHPgfvvr9JbzB6bx98f')}
              value={searchText}
              onChange={(ev) => setSearchText(ev.target.value)}
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col xs={24}>
            <Table
              columns={interfacesTableCols}
              dataSource={
                host?.interfaces?.filter((iface) =>
                  `${iface.name}${iface.addressString}`
                    .toLocaleLowerCase()
                    .includes(searchText.toLocaleLowerCase().trim()),
                ) ?? []
              }
              rowKey={(iface) => `${iface.name}${iface.addressString}`}
            />
          </Col>
        </Row>
      </>
    );
  }, [host?.interfaces, interfacesTableCols, searchText]);

  const hostTabs: TabsProps['items'] = useMemo(() => {
    return [
      {
        key: 'overview',
        label: t('5sQKidSqfNcKtwButDpLe'),
        children: host ? getOverviewContent() : <Skeleton active />,
      },
      {
        key: 'network-interface',
        label: t('klZmb4YieyIrlwvtX81gR'),
        children: host ? getNetworkInterfacesContent() : <Skeleton active />,
      },
    ];
  }, [getNetworkInterfacesContent, getOverviewContent, host]);

  useEffect(() => {
    loadDetails();
  }, [loadDetails]);

  // run only once
  useEffect(() => {
    const shouldEdit = queryParams.get('edit');
    if (shouldEdit === 'true') {
      setIsEditingNode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout.Content
      className="NetworkHostDetailsPage"
      style={{ position: 'relative', height: '100%', padding: props.isFullScreen ? 0 : 24 }}
    >
      <Skeleton loading={isLoading} active className="page-padding">
        {/* top bar */}
        <Row className="tabbed-page-row-padding">
          <Col xs={24}>
            <Link to={getNetworkRoute(networkId || '')}>{t('ivqjoJaki2F8M4iXoAQk')}</Link>
            <Row>
              <Col xs={18}>
                <Typography.Title level={2} style={{ marginTop: '.5rem', marginBottom: '2rem' }}>
                  {host?.name ?? '...'}
                  {node?.pendingdelete === false && <span style={{ marginLeft: '1rem' }}>{getHostHealth()}</span>}
                  {node?.pendingdelete !== false && (
                    <span style={{ marginLeft: '1rem' }}>
                      <Badge status="processing" color="red" text={t('n1rvhe2MhM6zo9X7GVh')} />
                    </span>
                  )}
                </Typography.Title>
              </Col>
              <Col xs={6} style={{ textAlign: 'right' }}>
                <Dropdown
                  placement="bottomRight"
                  menu={{
                    items: [
                      {
                        key: 'refresh-key',
                        label: t('xOdO6dKpbc9Pqi9oB539o'),
                        disabled: node?.pendingdelete !== false,
                        title: node?.pendingdelete !== false ? t('755601zJzeKrqqdVYdG42') : '',
                        onClick: (ev) => {
                          ev.domEvent.stopPropagation();
                          refreshHostKeys();
                        },
                      },
                      {
                        key: 'edit',
                        label: t('giSlJxeyxvkwuaiGyFDji'),
                        disabled: node?.pendingdelete !== false,
                        title: node?.pendingdelete !== false ? t('755601zJzeKrqqdVYdG42') : '',
                        onClick: (ev) => {
                          ev.domEvent.stopPropagation();
                          setIsEditingNode(true);
                        },
                      },
                      {
                        key: 'global-hpst',
                        label: t('zgspShUq0tZ5Z8M26yvlv'),
                        onClick: (ev) => {
                          ev.domEvent.stopPropagation();
                          navigate(getHostRoute(hostId ?? ''));
                        },
                      },
                      {
                        key: 'disconnect',
                        label: (
                          <Typography.Text type="warning">
                            {node?.connected ? t('bPrwDbvv2A9RqoyQxb4r') : t('2ey5ihxmZgNDnYa1EreCo')} network
                          </Typography.Text>
                        ),
                        onClick: (ev) => {
                          ev.domEvent.stopPropagation();
                          promptConfirmDisconnect();
                        },
                      },
                      {
                        key: 'remove',
                        label: <Typography.Text type="danger">{t('t4D4CzDaaUztt1sqOg78G')}</Typography.Text>,
                        onClick: (ev) => {
                          ev.domEvent.stopPropagation();
                          promptConfirmRemove();
                        },
                      },
                    ],
                  }}
                >
                  <Button type="default" style={{ marginRight: '.5rem' }}>
                    <SettingOutlined /> {t('hu_6X3kGb7xhCtTeCQdiH')}
                  </Button>
                </Dropdown>
              </Col>
            </Row>

            <Tabs items={hostTabs} />
          </Col>
        </Row>
      </Skeleton>

      {/* misc */}
      {notifyCtx}
      {!!node && (
        <UpdateNodeModal
          isOpen={isEditingNode}
          node={node}
          onCancel={() => {
            setIsEditingNode(false);
          }}
          onUpdateNode={onUpdateNode}
        />
      )}
    </Layout.Content>
  );
}
