import { Host } from '@/models/Host';
import { AppRoutes } from '@/routes';
import { useStore } from '@/store/store';
import { getHostRoute } from '@/utils/RouteUtils';
import { MoreOutlined, PlusOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Input,
  Layout,
  MenuProps,
  Modal,
  notification,
  Row,
  Skeleton,
  Switch,
  Table,
  TableColumnsType,
  Tabs,
  TabsProps,
  Tag,
  Typography,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { PageProps } from '../../models/Page';
import './HostsPage.scss';
import { getNodeConnectivityStatus } from '@/utils/NodeUtils';
import { Network } from '@/models/Network';
import { HostsService } from '@/services/HostsService';
import { extractErrorMsg } from '@/utils/ServiceUtils';
import NewHostModal from '@/components/modals/new-host-modal/NewHostModal';

export default function HostsPage(props: PageProps) {
  const [notify, notifyCtx] = notification.useNotification();
  const store = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const hosts = store.hosts;
  const storeUpdateHost = store.updateHost;
  const storeDeleteHost = store.deleteHost;
  const storeFetchHosts = useStore((state) => state.fetchHosts);
  const storeFetchNetworks = useStore((state) => state.fetchNetworks);
  const [searchText, setSearchText] = useState('');
  const [hasLoaded, setHasLoaded] = useState(false);
  const [selectedHost, setSelectedHost] = useState<Host | null>(null);
  const [hasAdvicedHosts, setHasAdvicedHosts] = useState(false);
  const [isRefreshingHosts, setIsRefreshingHosts] = useState(false);
  const [isAddNewHostModalOpen, setIsAddNewHostModalOpen] = useState(false);

  const filteredNetworks = useMemo(() => {
    return store.networks;
  }, [store.networks]);

  const filteredHosts = useMemo(
    () =>
      hosts.filter((host) => {
        return host.name.toLowerCase().includes(searchText.toLowerCase());
      }),
    [hosts, searchText],
  );

  const refreshHostKeys = useCallback(
    (host: Host) => {
      Modal.confirm({
        title: t('778VjVpfshVNgfIuExjNd', { hostName: host.name }),
        content: t('rxhWzH73w7FesP58F7Twu'),
        onOk: async () => {
          try {
            await HostsService.refreshHostKeys(host.id);
            notify.success({
              message: t('ptMmb9B_1D23NrRcgBfV'),
              description: t('grRhSlwPy0sutUyDvZDeU'),
            });
          } catch (err) {
            notify.error({
              message: t('q0LfKtB1ZaXZo8YwxqMm'),
              description: extractErrorMsg(err as any),
            });
          }
        },
      });
    },
    [notify],
  );

  const requestHostPull = useCallback(
    (host: Host) => {
      Modal.confirm({
        title: t('0CAohVVrbNWe1KgQhAPv'),
        content: t('s3XfDw4qTkAyNlZKpGcOu', { hostName: host.name }),
        onOk: async () => {
          try {
            await HostsService.requestHostPull(host.id);
            notify.success({
              message: t('ui3nvMMoWoD05aNf6dERx'),
              description: t('vf67Qdh6UnPkQhyLjJi1o', { hostName: host.name }),
            });
          } catch (err) {
            notify.error({
              message: t('ptEoD8uaNLhbGuiYdMmTd'),
              description: extractErrorMsg(err as any),
            });
          }
        },
      });
    },
    [notify],
  );

  const confirmToggleHostDefaultness = useCallback(
    async (host: Host) => {
      Modal.confirm({
        title: t('q1DxaF5Ujg4MFsiTmEdJb'),
        content: t('6cZhQKAi6uvvwVs8dLmj', {
          defaultness: !host.isdefault ? t('nASgb7BPqLxAw1IbqwVp') : t('roFe4Ex3vtGoMaFluscX'),
        }),
        onOk: async () => {
          try {
            const newHost = (await HostsService.updateHost(host.id, { ...host, isdefault: !host.isdefault })).data;
            notify.success({ message: t('gitb7lrz7HkCo5JorBfQ', { hostId: host.id }) });
            storeUpdateHost(host.id, newHost);
          } catch (err) {
            notify.error({
              message: t('kSuTupoZxE9CFmIbxkRd'),
              description: extractErrorMsg(err as any),
            });
          }
        },
      });
    },
    [notify, storeUpdateHost],
  );

  const onEditHost = useCallback(
    (host: Host) => {
      navigate(getHostRoute(host, { edit: 'true' }));
    },
    [navigate],
  );

  const confirmDeleteHost = useCallback(
    async (host: Host) => {
      const assocNodes = store.nodes.filter((node) => node.hostid === host.id);

      Modal.confirm({
        title: t('ndJi6TIfujiJIp9VlrToU'),
        content: (
          <>
            <Row>
              <Col xs={24}>
                <Typography.Text>{t('e22OcN0lxd4ADmGedfn5a', { hostName: host.name })}</Typography.Text>
              </Col>
              {assocNodes.length > 0 && (
                <Col xs={24}>
                  <Typography.Text color="warning">{t('osSnBtu4mZlwLj8TwkLbs')}</Typography.Text>
                  <ul>
                    {assocNodes.map((node) => (
                      <li key={node.id}>{node.network}</li>
                    ))}
                  </ul>
                </Col>
              )}
            </Row>
          </>
        ),
        onOk: async () => {
          try {
            await HostsService.deleteHost(host.id, true);
            storeDeleteHost(host.id);
            notify.success({ message: t('rnRa1w43xyYrQ25TzYvT', { hostName: host.name }) });
          } catch (err) {
            notify.error({
              message: t('hg7olj_7tUbJNpaEHqCWz'),
              description: extractErrorMsg(err as any),
            });
          }
        },
      });
    },
    [notify, store.nodes, storeDeleteHost],
  );

  const refreshAllHostKeys = useCallback(() => {
    Modal.confirm({
      title: t('up14k5e2hUvQwbMnfHowZ'),
      content: t('cy7RkkvaHcEPamQb9MbIt'),
      onOk: async () => {
        try {
          setIsRefreshingHosts(true);
          await HostsService.refreshAllHostsKeys();
          notify.success({
            message: t('ystRwa2TgMn2bifYa4O4'),
            description: t('grRhSlwPy0sutUyDvZDeU'),
          });
        } catch (err) {
          notify.error({
            message: t('iiO98cQ66wMoLtAiXYe'),
            description: extractErrorMsg(err as any),
          });
        } finally {
          setIsRefreshingHosts(false);
        }
      },
    });
  }, [notify]);

  const hostsTableColumns: TableColumnsType<Host> = useMemo(
    () => [
      {
        title: t('3WHyqtcOa34N5vK0hJtLg'),
        dataIndex: 'name',
        render: (_, host) => <Link to={getHostRoute(host)}>{host.name}</Link>,
        sorter(a, b) {
          return a.name.localeCompare(b.name);
        },
        defaultSortOrder: 'ascend',
      },
      {
        title: t('31T4VGqDDgp5Vy56Sf3mI'),
        dataIndex: 'endpointip',
        render: (endpointip) => (
          <div onClick={(ev) => ev.stopPropagation()}>
            <Typography.Text>{endpointip}</Typography.Text>
          </div>
        ),
      },
      {
        title: t('0Zt9MGjz0_7vmqrE5Qnsb'),
        dataIndex: 'listenport',
        render: (listenport) => (
          <div onClick={(ev) => ev.stopPropagation()}>
            <Typography.Text>{listenport}</Typography.Text>
          </div>
        ),
      },
      {
        title: t('lIZnT8On5DjilQhJL'),
        dataIndex: 'version',
        render: (version) => (
          <div onClick={(ev) => ev.stopPropagation()}>
            <Typography.Text>{version}</Typography.Text>
          </div>
        ),
      },
      // {
      //   title: 'Relay status',
      //   render(_, host) {
      //     let relayer: Host | undefined;

      //     if (host.isrelayed) {
      //       relayer = hosts.find((h) => h.id === host.relayed_by);
      //     }

      //     return (
      //       <Space direction="horizontal" onClick={(ev) => ev.stopPropagation()}>
      //         <Tag color={host.isrelay ? 'success' : 'default'}>Relay</Tag>
      //         <Tag
      //           color={host.isrelayed ? 'blue' : 'default'}
      //           title={host.isrelayed ? `Relayed by "${relayer?.name}"` : ''}
      //         >
      //           Relayed
      //         </Tag>
      //       </Space>
      //     );
      //   },
      // },
      {
        title: t('7fY9K7Aioj5MpMEtc9Ni1'),
        render(_, host) {
          const nodeHealths = store.nodes
            .filter((n) => n.hostid === host.id)
            .map((n) => getNodeConnectivityStatus(n))
            .map((h) => {
              switch (h) {
                case 'healthy':
                  return 3;
                case 'warning':
                  return 2;
                case 'error':
                  return 1;
                default:
                  return 0;
              }
            })
            .filter((h) => h !== 0);

          let worstHealth = Number.MAX_SAFE_INTEGER;
          nodeHealths.forEach((h) => {
            worstHealth = Math.min(worstHealth, h);
          });

          switch (worstHealth) {
            default:
              return <Tag>{t('vv6Qpd3wDr3Gyi4Rh8p18')}</Tag>;
            case 1:
              return <Tag color="error">{t('yEWqSeJXmpNEmbkjuKxm')}</Tag>;
            case 2:
              return <Tag color="warning">{t('xGnH4kkvY9Cyz0M6xpK8')}</Tag>;
            case 3:
              return <Tag color="success">{t('hVVpxosa9q0qlLzRep6vT')}</Tag>;
          }
        },
      },
      {
        title: '',
        width: '5rem',
        render: (_, host) => (
          <div onClick={(ev) => ev.stopPropagation()}>
            <Button
              type="text"
              icon={<ReloadOutlined />}
              onClick={() => {
                requestHostPull(host);
              }}
            >
              {t('uOk0Y1pnBVqadLng27Czb')}
            </Button>
          </div>
        ),
      },
      {
        width: '1rem',
        render(_, host) {
          return (
            <Dropdown
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: 'default',
                    label: host.isdefault ? t('wdfkl56l4ZUk6QuUu429') : t('zo3wQr_2Im2xSojYd3NU'),
                    onClick: (ev) => {
                      ev.domEvent.stopPropagation();
                      confirmToggleHostDefaultness(host);
                    },
                  },
                  {
                    key: 'refresh',
                    label: t('bbEWpaMg0Asn8wWEcNv5c'),
                    onClick: (ev) => {
                      ev.domEvent.stopPropagation();
                      refreshHostKeys(host);
                    },
                  },
                  {
                    key: 'edit',
                    label: t('tf6wv8A3PDsGs5qeLKcRj'),
                    onClick: (ev) => {
                      ev.domEvent.stopPropagation();
                      onEditHost(host);
                    },
                  },
                  {
                    key: 'delete',
                    label: t('byBSb1APppwoFf6dMrqhq'),
                    danger: true,
                    onClick: (ev) => {
                      ev.domEvent.stopPropagation();
                      confirmDeleteHost(host);
                    },
                  },
                ] as MenuProps['items'],
              }}
            >
              <Button type="text" icon={<MoreOutlined />} />
            </Dropdown>
          );
        },
      },
    ],
    [confirmToggleHostDefaultness, confirmDeleteHost, onEditHost, refreshHostKeys, store.nodes],
  );

  const namHostsTableCols: TableColumnsType<Host> = useMemo(
    () => [
      {
        title: t('3WHyqtcOa34N5vK0hJtLg'),
        dataIndex: 'name',
        sorter(a, b) {
          return a.name.localeCompare(b.name);
        },
        defaultSortOrder: 'ascend',
      },
      {
        title: t('31T4VGqDDgp5Vy56Sf3mI'),
        dataIndex: 'endpointip',
      },
      {
        title: t('0Zt9MGjz0_7vmqrE5Qnsb'),
        dataIndex: 'listenport',
      },
      {
        width: '1rem',
        render(_, host) {
          return (
            <Dropdown
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: 'default',
                    label: (
                      <Typography.Text
                        onClick={(ev) => {
                          ev.stopPropagation();
                          confirmToggleHostDefaultness(host);
                        }}
                      >
                        {host.isdefault ? t('wdfkl56l4ZUk6QuUu429') : t('zo3wQr_2Im2xSojYd3NU')}
                      </Typography.Text>
                    ),
                  },
                  {
                    key: 'edit',
                    label: (
                      <Typography.Text
                        onClick={(ev) => {
                          ev.stopPropagation();
                          onEditHost(host);
                        }}
                      >
                        {t('tf6wv8A3PDsGs5qeLKcRj')}
                      </Typography.Text>
                    ),
                  },
                ] as MenuProps['items'],
              }}
            >
              <Button type="text" icon={<MoreOutlined />} />
            </Dropdown>
          );
        },
      },
    ],
    [confirmToggleHostDefaultness, onEditHost],
  );

  const networksTableCols: TableColumnsType<Network> = useMemo(
    () => [
      {
        title: t('3WHyqtcOa34N5vK0hJtLg'),
        dataIndex: 'netid',
        sorter(a, b) {
          return a.netid.localeCompare(b.netid);
        },
        defaultSortOrder: 'ascend',
      },
      {
        title: t('29t3RnUdZzBw1X2x7O_2d'),
        dataIndex: 'addressrange',
      },
      {
        title: t('zfKrX42MlxCWa2VhYDydh'),
        dataIndex: 'addressrange6',
      },
      selectedHost
        ? {
            title: t('t_5zgK6ZZdeln23ZaODqV'),
            render(_: any, network: Network) {
              const isConnected = store.nodes.some(
                (node) => node.network === network.netid && node.hostid === selectedHost.id,
              );
              return (
                <Switch
                  key={selectedHost.id}
                  checked={isConnected}
                  onChange={() => {
                    Modal.confirm({
                      title: `${isConnected ? t('hVkUIz2lgXiHzOXd3Lmxq') : t('z_9B4Fsxn6LQqQOckRLva')} ${t(
                        'spN3EDqLtjze56Zx9Oir',
                      )} ${selectedHost.name} ${isConnected ? t('vLry8jxhVrHxAApKaqOjJ') : t('aVyDoUwFM47yVCkEn64v')} ${
                        network.netid
                      }`,
                      async onOk() {
                        try {
                          await HostsService.updateHostsNetworks(
                            selectedHost.id,
                            network.netid,
                            isConnected ? t('ibUuPAe5uaiO16YtsFlJ') : t('nfiUsHvh3DvD0tFaY93t'),
                          );
                          // `Host successfully ${
                          //   isConnected ? t('yyAcfE84R4Mschn5uJEa1') : t('mdPMn8nr1mLuRyyHoiBcS')
                          // } network. It might take a while to reflect`
                          notify.success({
                            message: t('72pD61MeDoUcE7MYns7ys', {
                              isConnected: isConnected ? t('yyAcfE84R4Mschn5uJEa1') : t('mdPMn8nr1mLuRyyHoiBcS'),
                            }),
                            description: '',
                          });
                        } catch (err) {
                          notify.error({
                            message: t('3oPjsdKdtq3rStfcsm2xQ', {
                              operation: isConnected ? t('ze6qYok8YzzU9ZfvvYKs') : t('zxleSQqVNwVhggDiwtqMu'),
                              direction: isConnected ? t('vLry8jxhVrHxAApKaqOjJ') : t('aVyDoUwFM47yVCkEn64v'),
                            }),
                          });
                        }
                      },
                    });
                  }}
                />
              );
            },
          }
        : {},
    ],
    [notify, selectedHost, store.nodes],
  );

  // ui components
  const getOverviewContent = useCallback(() => {
    return (
      <Skeleton loading={!hasLoaded && store.isFetchingHosts} active title={true} className="page-padding">
        <Row className="">
          <Col xs={24}>
            <Table
              columns={hostsTableColumns}
              dataSource={filteredHosts}
              rowKey="id"
              onRow={(host) => ({
                onClick: () => {
                  navigate(getHostRoute(host));
                },
              })}
            />
          </Col>
        </Row>
      </Skeleton>
    );
  }, [filteredHosts, hasLoaded, store.isFetchingHosts, hostsTableColumns, navigate]);

  const getNetworkAccessContent = useCallback(() => {
    return (
      <Skeleton loading={!hasLoaded && store.isFetchingHosts} active title={true} className="page-padding">
        <>
          <Row className="" justify="space-between">
            <Col xs={12}>
              <Row style={{ width: '100%' }}>
                <Col xs={24}>
                  <Typography.Title style={{ marginTop: '0px' }} level={5}>
                    {t('0BKhlb9aXByzTp1svGp')}
                  </Typography.Title>
                </Col>
              </Row>
              <Row style={{ marginTop: '1rem' }}>
                <Col xs={23}>
                  <Table
                    columns={namHostsTableCols}
                    dataSource={filteredHosts}
                    rowKey="id"
                    size="small"
                    rowClassName={(host) => {
                      return host.id === selectedHost?.id ? 'selected-row' : '';
                    }}
                    onRow={(host) => {
                      return {
                        onClick: () => {
                          if (selectedHost?.id === host.id) setSelectedHost(null);
                          else setSelectedHost(host);
                        },
                      };
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row style={{ width: '100%' }}>
                <Col xs={12}>
                  <Typography.Title style={{ marginTop: '0px' }} level={5}>
                    {t('etLphumlcFFvQTir0am8T')}
                  </Typography.Title>
                </Col>
              </Row>
              <Row style={{ marginTop: '1rem' }}>
                <Col xs={24}>
                  <Table columns={networksTableCols} dataSource={filteredNetworks} rowKey="netid" size="small" />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      </Skeleton>
    );
  }, [
    hasLoaded,
    store.isFetchingHosts,
    namHostsTableCols,
    filteredHosts,
    selectedHost,
    networksTableCols,
    filteredNetworks,
  ]);

  const tabs: TabsProps['items'] = useMemo(
    () => [
      {
        key: 'overview',
        label: t('5sQKidSqfNcKtwButDpLe'),
        children: getOverviewContent(),
      },
      {
        key: 'network-access',
        label: t('80rJtkSgNVq0eHnKY7J'),
        children: getNetworkAccessContent(),
      },
    ],
    [getOverviewContent, getNetworkAccessContent],
  );

  useEffect(() => {
    storeFetchHosts();
    storeFetchNetworks();
    setHasLoaded(true);
  }, [storeFetchHosts, storeFetchNetworks]);

  useEffect(() => {
    if (hosts.length <= 1 && !hasAdvicedHosts) {
      setHasAdvicedHosts(true);
      notify.info({
        message: t('info.connectmultiplehosts'),
        description: t('info.connectatleasttwohostsonanetworktobegincommunication'),
        duration: 10,
        btn: (
          <>
            <Button type="primary" size="small" onClick={() => setIsAddNewHostModalOpen(true)}>
              {t('hosts.connectahost')}
            </Button>
          </>
        ),
      });
    }
  }, [hasAdvicedHosts, hosts.length, notify, t]);

  return (
    <Layout.Content
      className="HostsPage"
      style={{ position: 'relative', height: '100%', padding: props.isFullScreen ? 0 : 24 }}
    >
      <Skeleton loading={!hasLoaded && store.isFetchingHosts} active title={true} className="page-padding">
        {hosts.length === 0 && (
          <>
            <Row
              className="page-padding"
              style={{
                background: 'linear-gradient(90deg, #52379F 0%, #B66666 100%)',
              }}
            >
              <Col xs={(24 * 2) / 3}>
                <Typography.Title level={3} style={{ color: 'white ' }}>
                  {t('0BKhlb9aXByzTp1svGp')}
                </Typography.Title>
                <Typography.Text style={{ color: 'white ' }}>{t('lOj2frYAdE8tTo4v_53Rw')}</Typography.Text>
              </Col>
              <Col xs={(24 * 1) / 3} style={{ position: 'relative' }}>
                <Card className="header-card" style={{ height: '20rem', position: 'absolute', width: '100%' }}>
                  <Typography.Title level={3}>{t('mxzFexFhy2_5Y5syYwcl')}</Typography.Title>
                  <Typography.Text>{t('xsvh0kIyN44FrHuMeFo6J')}</Typography.Text>
                  <Row style={{ marginTop: 'auto' }}>
                    <Col>
                      <Button type="primary" size="large" onClick={() => setIsAddNewHostModalOpen(true)}>
                        <PlusOutlined /> {t('eKr4lWiVm3uzxQ75Y0yUf')}
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row style={{ marginTop: '8rem', marginBottom: '4rem', padding: '0px 5.125rem' }} gutter={[0, 20]}>
              <Col xs={24}>
                <Typography.Title level={3}>{t('eKr4lWiVm3uzxQ75Y0yUf')}</Typography.Title>
              </Col>

              <Col xs={7} style={{ marginRight: '1rem' }}>
                <Card>
                  <Typography.Title level={4} style={{ marginTop: '0px' }}>
                    {t('gCah2Ui7YkyHqaynVbBdc')}
                  </Typography.Title>
                  <Typography.Text>{t('lxlwQpnZl5ogOf75Glyne')}</Typography.Text>
                </Card>
              </Col>
              <Col xs={7} style={{ marginRight: '1rem' }}>
                <Card>
                  <Typography.Title level={4} style={{ marginTop: '0px' }}>
                    {t('82bMpTlSzF1YcDfhZ4Jh')}
                  </Typography.Title>
                  <Typography.Text>{t('nqSkLjnt9P2pjshCrTml')}</Typography.Text>
                </Card>
              </Col>
              <Col xs={7}>
                <Card>
                  <Typography.Title level={4} style={{ marginTop: '0px' }}>
                    {t('mqElTm0KsOKxQtk35b9hQ')}
                  </Typography.Title>
                  <Typography.Text>
                    {t('dC0rjoIpG2XmS8SvngRv')}
                    <Link to={AppRoutes.HOSTS_ROUTE}>{t('wfJg4LfGuKb40Bkr8oBqx')}</Link>.
                  </Typography.Text>
                </Card>
              </Col>
            </Row>
          </>
        )}
        {hosts.length > 0 && (
          <>
            <Row className="page-row-padding">
              <Col xs={24}>
                <Typography.Title level={3}>{t('0BKhlb9aXByzTp1svGp')}</Typography.Title>
              </Col>
            </Row>

            <Row className="page-row-padding" justify="space-between">
              <Col xs={12} md={8}>
                <Input
                  size="large"
                  placeholder="Search hosts"
                  value={searchText}
                  onChange={(ev) => setSearchText(ev.target.value)}
                  prefix={<SearchOutlined />}
                />
              </Col>
              <Col xs={12} md={6} style={{ textAlign: 'right' }}>
                <Button
                  size="large"
                  style={{ marginRight: '1rem' }}
                  onClick={() => refreshAllHostKeys()}
                  loading={isRefreshingHosts}
                >
                  <ReloadOutlined /> {t('97DCco6718XMr3QQsUsKu')}
                </Button>

                <Button type="primary" size="large" onClick={() => setIsAddNewHostModalOpen(true)}>
                  <PlusOutlined /> {t('rqGnFzkw3ZtdKfckTMfE')}
                </Button>
              </Col>
            </Row>

            <Row className="page-row-padding" justify="space-between">
              <Col xs={24}>
                <Tabs defaultActiveKey="1" items={tabs} />
              </Col>
            </Row>
          </>
        )}
      </Skeleton>

      {/* misc */}
      {notifyCtx}
      <NewHostModal
        isOpen={isAddNewHostModalOpen}
        onFinish={() => {
          setIsAddNewHostModalOpen(false);
          navigate(AppRoutes.HOSTS_ROUTE);
        }}
        onCancel={() => setIsAddNewHostModalOpen(false)}
      />
    </Layout.Content>
  );
}
