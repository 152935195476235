import { Alert, Button, Card, Col, Dropdown, Input, Layout, Row, Space, Tooltip, Typography } from 'antd';
import {
  ArrowRightOutlined,
  DownOutlined,
  GlobalOutlined,
  LaptopOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import UpgradeModal from '../components/modals/upgrade-modal/UpgradeModal';
import { PageProps } from '../models/Page';
import { AppRoutes } from '../routes';
import { useNavigate } from 'react-router-dom';
import AddNetworkModal from '@/components/modals/add-network-modal/AddNetworkModal';
import { useState } from 'react';
import { useStore } from '@/store/store';
import { getAmuiUrl, getLicenseDashboardUrl } from '@/utils/RouteUtils';
import NewHostModal from '@/components/modals/new-host-modal/NewHostModal';
import { getBrandingConfig, isSaasBuild } from '@/services/BaseService';
import { useTranslation } from 'react-i18next';

export default function DashboardPage(props: PageProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const store = useStore();

  const isServerEE = store.serverConfig?.IsEE === 'yes';
  const [isAddNetworkModalOpen, setIsAddNetworkModalOpen] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isNewHostModalOpen, setIsNewHostModalOpen] = useState(false);

  return (
    <Layout.Content style={{ padding: props.isFullScreen ? 0 : 24 }}>
      <Row>
        <Layout.Header style={{ width: '100%', padding: '0px', backgroundColor: 'transparent' }}>
          <Row>
            {/* <Col xs={6}></Col> */}
            <Col xs={12} style={{ textAlign: 'left' }}>
              <Space direction="horizontal" size="large" align="end">
                <Input
                  placeholder={t('kgAxVnjExRdCfyoDqo6Je')}
                  prefix={<SearchOutlined />}
                  style={{ borderRadius: '24px', width: '20rem' }}
                />
                <Dropdown.Button
                  style={{ marginTop: '-3rem', height: '100%' }}
                  type="primary"
                  menu={{
                    items: [
                      {
                        key: 'host',
                        label: (
                          <>
                            <LaptopOutlined /> <Typography.Text>{t('02Br_5yGfO2i3iawi4Ju')}</Typography.Text>
                          </>
                        ),
                        onClick: () => {
                          // navigate(getNewHostRoute(AppRoutes.HOSTS_ROUTE));
                          setIsNewHostModalOpen(true);
                        },
                      },
                    ],
                  }}
                  placement="bottomRight"
                  icon={<DownOutlined />}
                  onClick={() => setIsAddNetworkModalOpen(true)}
                >
                  <GlobalOutlined /> {t('xV611ChID7xzXr6gJb9r')}
                </Dropdown.Button>
                {/* <Tooltip title="Docs">
                  <QuestionCircleOutlined
                    style={{ cursor: 'pointer', fontSize: '1.2rem' }}
                    onClick={() => {
                      window.open('https://docs.netmaker.io', '_blank');
                    }}
                  />
                </Tooltip> */}
                {/* <Tooltip title="Notifications">
                  <BellOutlined
                    style={{ cursor: 'pointer', fontSize: '1.2rem' }}
                    onClick={() => {
                      // TODO: notifications
                    }}
                  />
                </Tooltip> */}
              </Space>
            </Col>
          </Row>
        </Layout.Header>
      </Row>
      <Row>
        <Col>
          <Space direction="vertical" size="middle">
            <Card>
              <h3>{t('nXylxhWr0YEuXyrtDRbM9', { productName: getBrandingConfig().productName })}</h3>
              <p>
                {t('yyZt92xeLtHWwSXpTpwx0', {
                  productName1: getBrandingConfig().productName,
                  productName2: getBrandingConfig().productName,
                })}
              </p>
              {/* <div>
                <Button type="link" href="https://netmaker.io/demo-page" target="_blank" rel="noreferrer">
                  <ArrowRightOutlined />
                  {t('rkRqgmOMnlkszmGp5K61')}
                </Button>
              </div> */}
            </Card>
            {store.networks.length === 0 && (
              <Card style={{ maxWidth: '30%' }}>
                <h3>{t('jk8ieoTv8QAaKvXw3TsQ')}</h3>
                <p>{t('aCuGi0GaNUnfp4d0iM00')}</p>
                <div>
                  <Button type="primary" onClick={() => setIsAddNetworkModalOpen(true)}>
                    <PlusOutlined />
                    {t('dKxtgRi2DaY1_5prdZ3qu')}
                  </Button>
                </div>
              </Card>
            )}
            {store.hosts.length === 0 && (
              <Card style={{ maxWidth: '30%' }}>
                <h3>{t('j654uygVCItsoMhiWb')}</h3>
                <p>{t('xsvh0kIyN44FrHuMeFo6J')}</p>
                <div>
                  <Button type="primary" onClick={() => setIsNewHostModalOpen(true)}>
                    <PlusOutlined />
                    {t('vtLshUpr0Rxswv9k9cgW9')}
                  </Button>
                </div>
              </Card>
            )}
          </Space>
        </Col>
      </Row>

      {/* misc */}
      <UpgradeModal isOpen={isUpgradeModalOpen} onCancel={() => setIsUpgradeModalOpen(false)} />
      <AddNetworkModal
        isOpen={isAddNetworkModalOpen}
        onCreateNetwork={() => {
          setIsAddNetworkModalOpen(false);
          navigate(AppRoutes.NETWORKS_ROUTE);
        }}
        onCancel={() => setIsAddNetworkModalOpen(false)}
      />
      <NewHostModal
        isOpen={isNewHostModalOpen}
        onFinish={() => navigate(AppRoutes.HOSTS_ROUTE)}
        onCancel={() => setIsNewHostModalOpen(false)}
      />
    </Layout.Content>
  );
}
