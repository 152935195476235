import { AppRoutes } from '@/routes';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function Error404Page() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Result
      status="404"
      title="404"
      subTitle={t('3Iek6Ku1GRoiHvJLqUiEe')}
      extra={
        <Button type="primary" onClick={() => navigate(AppRoutes.DASHBOARD_ROUTE)}>
          {t('mDMaGv9eM8Jg23Emu1m4d')}
        </Button>
      }
    />
  );
}
