import { Button, Col, Divider, Image, Modal, notification, Row, Select, Switch, Typography } from 'antd';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import '../CustomModal.scss';
import { DownloadOutlined } from '@ant-design/icons';
import { download, extractErrorMsg } from '@/utils/ServiceUtils';
import { NodesService } from '@/services/NodesService';
import { ExternalClient } from '@/models/ExternalClient';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';

interface ClientDetailsModalProps {
  isOpen: boolean;
  client: ExternalClient;
  closeModal?: () => void;
  onOk?: (e: MouseEvent<HTMLButtonElement>) => void;
  onCancel?: (e: MouseEvent<HTMLButtonElement>) => void;
  onUpdateClient: (newClient: ExternalClient) => void;
}

function convertQrCodeArrayBufferToImgString(qrData: ArrayBuffer): string {
  return 'data:image/png;base64,' + Buffer.from(qrData).toString('base64');
}

export default function ClientDetailsModal({ client, isOpen, onCancel, onUpdateClient }: ClientDetailsModalProps) {
  const [notify, notifyCtx] = notification.useNotification();
  const { t } = useTranslation();

  const [qrCode, setQrCode] = useState(' '); // hack to allow qr code display

  const downloadClientData = async () => {
    try {
      notify.info({ message: t('nBi4mjd_3b6dshSFpHrwy') });
      const qrData = (await NodesService.getExternalClientConfig(client.clientid, client.network, 'file'))
        .data as string;
      download(`${client.clientid}.conf`, qrData);
    } catch (err) {
      notify.error({
        message: t('rpvHMbegAce3ujWzHvI'),
        description: extractErrorMsg(err as any),
      });
    }
  };

  const loadQrCode = useCallback(async () => {
    try {
      const qrData = (await NodesService.getExternalClientConfig(client.clientid, client.network, 'qr'))
        .data as ArrayBuffer;
      setQrCode(convertQrCodeArrayBufferToImgString(qrData));
    } catch (err) {
      notify.error({
        message: t('ph7RByhNMdrmxRnRVu7Sh'),
        description: extractErrorMsg(err as any),
      });
    }
  }, [client.clientid, client.network, notify, t]);

  const toggleClientStatus = useCallback(
    async (newStatus: boolean) => {
      Modal.confirm({
        title: t('itcsELtVdChxDJa3enEf3', {
          status: newStatus ? t('aQliv5H2LqWFbqOCbTd') : t('bvbSkVcljzOVtKv_3uZ6M'),
          client: client.clientid,
        }),
        content: t('9Gb5dXMzYSu5HIeA5Te', {
          status: newStatus ? t('aQliv5H2LqWFbqOCbTd') : t('bvbSkVcljzOVtKv_3uZ6M'),
          client: client.clientid,
        }),
        onOk: async () => {
          try {
            const newClient = (
              await NodesService.updateExternalClient(client.clientid, client.network, {
                ...client,
                clientid: client.clientid,
                enabled: newStatus,
              })
            ).data;
            onUpdateClient(newClient);
          } catch (err) {
            notify.error({
              message: t('uq0rVBkD97WTt0vFQnB4'),
              description: extractErrorMsg(err as any),
            });
          }
        },
      });
    },
    [client, notify, onUpdateClient],
  );

  useEffect(() => {
    loadQrCode();
  }, [loadQrCode]);

  return (
    <Modal
      title={<span style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{t('fGtJsZtD4gBGkI_6wZfBv')}</span>}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      centered
      className="CustomModal"
      style={{ minWidth: '50vw' }}
    >
      <Divider style={{ margin: '0px 0px 2rem 0px' }} />
      <div className="CustomModalBody">
        <Row>
          <Col xs={8}>
            <Typography.Text>{t('wqDzwbG2upD2a7QaQvni')}</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text>{client.clientid}</Typography.Text>
          </Col>
        </Row>
        <Divider style={{ margin: '1rem 0px 1rem 0px' }} />

        <Row>
          <Col xs={8}>
            <Typography.Text>{t('4fNXvurl2bX9LfJMmXhp')}</Typography.Text>
          </Col>
          <Col xs={16}>
            <Select
              key={client.clientid}
              mode="multiple"
              disabled
              placeholder={t('4fNXvurl2bX9LfJMmXhp')}
              defaultValue={[client.address, client.address6].concat(
                client.extraallowedips ? client.extraallowedips : [],
              )}
            />
          </Col>
        </Row>
        <Divider style={{ margin: '1rem 0px 1rem 0px' }} />

        <Row>
          <Col xs={8}>
            <Typography.Text>{t('cGgb27UyY7xtNh4RYt')}</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text copyable>{client.publickey}</Typography.Text>
          </Col>
        </Row>
        <Divider style={{ margin: '1rem 0px 1rem 0px' }} />

        <Row>
          <Col xs={8}>
            <Typography.Text>{t('dyfOhsh0Ts9Txac3Eecsy')}</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text copyable>{client.dns}</Typography.Text>
          </Col>
        </Row>
        <Divider style={{ margin: '1rem 0px 1rem 0px' }} />

        <Row>
          <Col xs={8}>
            <Typography.Text>{t('cNoWUpwy1jksCWlF7ffDt')}</Typography.Text>
          </Col>
          <Col xs={16}>
            <Switch checked={client.enabled} onChange={(checked) => toggleClientStatus(checked)} />
          </Col>
        </Row>
        <Divider style={{ margin: '1rem 0px 1rem 0px' }} />

        <Row>
          <Col xs={24}>
            <Image
              loading="eager"
              className="qr-code-container"
              preview={{ width: 600, height: 600 }}
              alt={`qr code for client ${client.clientid}`}
              src={qrCode}
              style={{ borderRadius: '8px' }}
              width={256}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col xs={24}>
            <Button onClick={downloadClientData}>
              <DownloadOutlined /> {t('78VcxERgww3B1vqbE0O0n')}
            </Button>
          </Col>
        </Row>
      </div>

      {/* misc */}
      {notifyCtx}
    </Modal>
  );
}
