import { LanguageResource } from '../type';

export const zh: LanguageResource = {
  common: {
    hello: 'Hello',
    hosts: '主机',
    or: '或者',
    here: '这里',
    reason: '原因',
    licensedashboard: 'Licence dashboard',
  },
  auth: {
    login: '登录',
    signup: '注册',
    terms5: '注册即表示您同意我们的',
  },
  signin: {
    signin: '登录',
    rememberme: '记住我',
    forgotpassword: '忘记密码?',
    signup: '注册',
    logout: '注销',
    username: '用户名',
    password: '密码',
    'confirm-password': '确认密码',
    terms1: '注册即表示您同意我们的',
    terms2: '服务条款',
    terms3: '和',
    terms4: '隐私政策',
    sso: 'Login with SSO',
    or: 'or',
  },
  error: {
    servermalfunction: '服务器故障',
    contactyourserveradmin: '联系管理员或者检查你的网络设置',
    billingerroroccured: '授权错误',
    checkbillingsetting: '检查授权设置',
  },
  info: {
    connectmultiplehosts: '连接多台主机',
    connectatleasttwohostsonanetworktobegincommunication: '至少连接网络上两台主机开始进行通信',
  },
  hosts: {
    connectahost: '连接主机',
  },
};
