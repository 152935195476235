import UpdateHostModal from '@/components/modals/update-host-modal/UpdateHostModal';
import { Host } from '@/models/Host';
import { Interface } from '@/models/Interface';
import { AppRoutes } from '@/routes';
import { HostsService } from '@/services/HostsService';
import { useStore } from '@/store/store';
import { getNodeConnectivityStatus } from '@/utils/NodeUtils';
import { extractErrorMsg } from '@/utils/ServiceUtils';
import { ExclamationCircleFilled, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Skeleton,
  Table,
  TableColumnsType,
  Tabs,
  TabsProps,
  Tag,
  theme,
  Typography,
} from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageProps } from '../../models/Page';

import './HostDetailsPage.scss';
import { useQuery } from '@/utils/RouteUtils';
import { useTranslation } from 'react-i18next';

export default function HostDetailsPage(props: PageProps) {
  const { t } = useTranslation();
  const { hostId } = useParams<{ hostId: string }>();
  const navigate = useNavigate();
  const store = useStore();
  const [notify, notifyCtx] = notification.useNotification();
  const { token: themeToken } = theme.useToken();
  const queryParams = useQuery();

  const storeUpdateHost = store.updateHost;
  const storeDeleteHost = store.deleteHost;
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingHost, setIsEditingHost] = useState(false);
  const [host, setHost] = useState<Host | null>(null);
  const [searchText, setSearchText] = useState('');

  const interfacesTableCols: TableColumnsType<Interface> = useMemo(
    () => [
      {
        title: t('3WHyqtcOa34N5vK0hJtLg'),
        dataIndex: 'name',
        render: (name) => {
          return (
            <Typography.Text>
              {name} {name === host?.defaultinterface ? <Tag>{t('9BfFao234Ivv8DGvIVfS')}</Tag> : <></>}
            </Typography.Text>
          );
        },
      },
      {
        title: t('p1nHzRj8xukU5cD23p2Ax'),
        dataIndex: 'addressString',
      },
    ],
    [host?.defaultinterface],
  );

  const onUpdateHost = useCallback(() => {
    setIsEditingHost(false);
  }, []);

  const getHostHealth = useCallback(() => {
    const nodeHealths = store.nodes
      .filter((n) => n.hostid === host?.id)
      .map((n) => getNodeConnectivityStatus(n))
      .map((h) => {
        switch (h) {
          case 'healthy':
            return 3;
          case 'warning':
            return 2;
          case 'error':
            return 1;
          default:
            return 0;
        }
      })
      .filter((h) => h !== 0);

    let worstHealth = Number.MAX_SAFE_INTEGER;
    nodeHealths.forEach((h) => {
      worstHealth = Math.min(worstHealth, h);
    });

    switch (worstHealth) {
      default:
        return <Tag>&#9679; {t('vv6Qpd3wDr3Gyi4Rh8p18')}</Tag>;
      case 1:
        return <Tag color="error">&#9679; {t('yEWqSeJXmpNEmbkjuKxm')}</Tag>;
      case 2:
        return <Tag color="warning">&#9679; {t('xGnH4kkvY9Cyz0M6xpK8')}</Tag>;
      case 3:
        return <Tag color="success">&#9679; {t('hVVpxosa9q0qlLzRep6vT')}</Tag>;
    }
  }, [host?.id, store.nodes]);

  const loadHost = useCallback(() => {
    setIsLoading(true);
    if (!hostId) {
      navigate(AppRoutes.HOSTS_ROUTE);
    }
    // load from store
    const host = store.hosts.find((h) => h.id === hostId);
    if (!host) {
      notify.error({ message: t('waDqpHp0Jhlr9aJlTzs5', { hostId }) });
      navigate(AppRoutes.HOSTS_ROUTE);
      return;
    }
    setHost(host);

    setIsLoading(false);
  }, [hostId, store.hosts, navigate, notify]);

  const onHostDelete = useCallback(async () => {
    try {
      if (!hostId) {
        throw new Error(t('0Rtc1EugKilKGu6ttDt9B'));
      }
      await HostsService.deleteHost(hostId, true);
      notify.success({ message: t('rnRa1w43xyYrQ25TzYvT', { hostName: host?.name }) });
      storeDeleteHost(hostId);
      navigate(AppRoutes.HOSTS_ROUTE);
    } catch (err) {
      if (err instanceof AxiosError) {
        notify.error({
          message: t('hg7olj_7tUbJNpaEHqCWz'),
          description: extractErrorMsg(err),
        });
      } else {
        notify.error({
          message: err instanceof Error ? err.message : t('hg7olj_7tUbJNpaEHqCWz'),
        });
      }
    }
  }, [hostId, notify, host?.name, storeDeleteHost, navigate]);

  const promptConfirmDelete = () => {
    if (!host) return;
    const assocNodes = store.nodes.filter((node) => node.hostid === host.id);

    Modal.confirm({
      title: t('ul92MmTjtG4Mb2_7YiSb', { hostName: host?.name }),
      icon: <ExclamationCircleFilled />,
      content: (
        <>
          <Row>
            {assocNodes.length > 0 && (
              <Col xs={24}>
                <Typography.Text color="warning">{t('osSnBtu4mZlwLj8TwkLbs')}</Typography.Text>
                <ul>
                  {assocNodes.map((node) => (
                    <li key={node.id}>{node.network}</li>
                  ))}
                </ul>
              </Col>
            )}
          </Row>
        </>
      ),
      onOk() {
        onHostDelete();
      },
    });
  };

  const confirmToggleHostDefaultness = useCallback(async () => {
    if (!host) return;
    Modal.confirm({
      title: t('q1DxaF5Ujg4MFsiTmEdJb'),
      content: t('6cZhQKAi6uvvwVs8dLmj', {
        defaultness: !host.isdefault ? t('nASgb7BPqLxAw1IbqwVp') : t('roFe4Ex3vtGoMaFluscX'),
      }),
      onOk: async () => {
        try {
          const newHost = (await HostsService.updateHost(host.id, { ...host, isdefault: !host.isdefault })).data;
          notify.success({ message: t('gitb7lrz7HkCo5JorBfQ', { hostId: host.id }) });
          storeUpdateHost(host.id, newHost);
        } catch (err) {
          notify.error({
            message: t('kSuTupoZxE9CFmIbxkRd'),
            description: extractErrorMsg(err as any),
          });
        }
      },
    });
  }, [host, notify, storeUpdateHost]);

  const refreshHostKeys = useCallback(() => {
    if (!hostId) return;
    Modal.confirm({
      title: t('eUKw_5gCe0A65Xi7HoXmA'),
      content: t('n2H4GtoNqMvX6o0ffQmj6'),
      onOk: async () => {
        try {
          await HostsService.refreshHostKeys(hostId);
          notify.success({
            message: t('ptMmb9B_1D23NrRcgBfV'),
            description: t('grRhSlwPy0sutUyDvZDeU'),
          });
        } catch (err) {
          notify.error({
            message: t('q0LfKtB1ZaXZo8YwxqMm'),
            description: extractErrorMsg(err as any),
          });
        }
      },
    });
  }, [notify, hostId]);

  const requestHostPull = useCallback(() => {
    if (!hostId) return;
    Modal.confirm({
      title: t('0CAohVVrbNWe1KgQhAPv'),
      content: t('p0Ymy1XFnH1Gaepfglr'),
      onOk: async () => {
        try {
          await HostsService.requestHostPull(hostId);
          notify.success({
            message: t('ui3nvMMoWoD05aNf6dERx'),
            description: t('chVCeZmK9IuwIdB2Ygypa'),
          });
        } catch (err) {
          notify.error({
            message: t('ptEoD8uaNLhbGuiYdMmTd'),
            description: extractErrorMsg(err as any),
          });
        }
      },
    });
  }, [notify, hostId]);

  const getOverviewContent = useCallback(() => {
    if (!host) return <Skeleton active />;
    return (
      <div
        className=""
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexFlow: 'column nowrap',
          // backgroundColor: 'black',
        }}
      >
        <Card style={{ width: '50%' }}>
          <Typography.Title level={5} style={{ marginTop: '0rem' }}>
            {t('bv7LoOmEDlk5yV6QaAm')}
          </Typography.Title>

          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="host-details_id"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('wqDzwbG2upD2a7QaQvni')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{host.id}</Typography.Text>
            </Col>
          </Row>
          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="host-details_endpoint"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('31T4VGqDDgp5Vy56Sf3mI')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{host.endpointip}</Typography.Text>
            </Col>
          </Row>
          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="host-details_listenport"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('jktoRtH3nMfw9egqouHl')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{host.listenport}</Typography.Text>
            </Col>
          </Row>
          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="host-details_macaddress"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('dv8Z8VAo0McrqSpU6TCoO')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{host.macaddress}</Typography.Text>
            </Col>
          </Row>
          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="host-details_mtu"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('c2kSJejRVktLokw67PRv')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{host.mtu}</Typography.Text>
            </Col>
          </Row>
          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="host-details_publickey"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('nrw6eqACmLurlzLeH8L5h')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{host.publickey}</Typography.Text>
            </Col>
          </Row>
          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="host-details_os"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('teygqjva5yoip6w9Vjb2d')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{host.os}</Typography.Text>
            </Col>
          </Row>
          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="host-details_version"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('lIZnT8On5DjilQhJL')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{host.version}</Typography.Text>
            </Col>
          </Row>
          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="host-details_verbosity"
          >
            <Col xs={12}>
              <Typography.Text disabled>Verbosity</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{host.verbosity}</Typography.Text>
            </Col>
          </Row>
          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="host-details_defaultinterface"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('9OnAl8wGroVprAuRy1zet')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{host.defaultinterface}</Typography.Text>
            </Col>
          </Row>
          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="host-details_isdefault"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('frZp4cX5H9hbsjSvdaf9M')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>
                {host.isdefault ? t('wns6iOjgjBoyQCwV0T8A') : t('6mlDqPzLSztraHwU7qOlk')}
              </Typography.Text>
            </Col>
          </Row>
          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="host-details_isstatic"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('uKb52eKpVaAz56eWmqcL5')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>
                {host.isstatic ? t('wns6iOjgjBoyQCwV0T8A') : t('6mlDqPzLSztraHwU7qOlk')}
              </Typography.Text>
            </Col>
          </Row>
          <Row
            style={{ borderBottom: `1px solid ${themeToken.colorBorder}`, padding: '.5rem 0rem' }}
            data-nmui-intercom="host-details_debug"
          >
            <Col xs={12}>
              <Typography.Text disabled>{t('vClR7hI7gGhePJamGxGt')}</Typography.Text>
            </Col>
            <Col xs={12}>
              <Typography.Text>{host.debug ? t('wns6iOjgjBoyQCwV0T8A') : t('6mlDqPzLSztraHwU7qOlk')}</Typography.Text>
            </Col>
          </Row>
        </Card>

        {/* <Card style={{ width: '50%', marginTop: '2rem' }}>
          <Typography.Title level={5} style={{ marginTop: '0rem' }}>
            Advanced settings
          </Typography.Title>
        </Card> */}
      </div>
    );
  }, [host, themeToken.colorBorder]);

  const getNetworkInterfacesContent = useCallback(() => {
    return (
      <>
        <Row>
          <Col xs={12} md={8}>
            <Input
              size="large"
              placeholder="Search interfaces"
              value={searchText}
              onChange={(ev) => setSearchText(ev.target.value)}
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col xs={24}>
            <Table
              columns={interfacesTableCols}
              dataSource={
                host?.interfaces?.filter((iface) =>
                  `${iface.name}${iface.addressString}`
                    .toLocaleLowerCase()
                    .includes(searchText.toLocaleLowerCase().trim()),
                ) ?? []
              }
              rowKey={(iface) => `${iface.name}${iface.addressString}`}
            />
          </Col>
        </Row>
      </>
    );
  }, [host?.interfaces, interfacesTableCols, searchText]);

  const hostTabs: TabsProps['items'] = useMemo(() => {
    return [
      {
        key: 'overview',
        label: t('5sQKidSqfNcKtwButDpLe'),
        children: host ? getOverviewContent() : <Skeleton active />,
      },
      {
        key: 'network-interface',
        label: t('klZmb4YieyIrlwvtX81gR'),
        children: host ? getNetworkInterfacesContent() : <Skeleton active />,
      },
    ];
  }, [getNetworkInterfacesContent, getOverviewContent, host]);

  useEffect(() => {
    loadHost();
  }, [loadHost]);

  // run only once
  useEffect(() => {
    const shouldEdit = queryParams.get('edit');
    if (shouldEdit === 'true') {
      setIsEditingHost(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout.Content
      className="HostDetailsPage"
      style={{ position: 'relative', height: '100%', padding: props.isFullScreen ? 0 : 24 }}
    >
      <Skeleton loading={isLoading} active className="page-padding">
        {/* top bar */}
        <Row className="tabbed-page-row-padding">
          <Col xs={24}>
            <Link to={AppRoutes.HOSTS_ROUTE}>{t('rPfQFysmlREnSzXNv54')}</Link>
            <Row>
              <Col xs={18}>
                <Typography.Title level={2} style={{ marginTop: '.5rem', marginBottom: '2rem' }}>
                  {host?.name ?? '...'}
                  <span style={{ marginLeft: '1rem' }}>{getHostHealth()}</span>
                </Typography.Title>
              </Col>
              <Col xs={6} style={{ textAlign: 'right' }}>
                <Dropdown
                  placement="bottomRight"
                  menu={{
                    items: [
                      {
                        key: 'default',
                        label: (
                          <Typography.Text
                            onClick={(ev) => {
                              ev.stopPropagation();
                              confirmToggleHostDefaultness();
                            }}
                          >
                            {host?.isdefault ? t('wdfkl56l4ZUk6QuUu429') : t('zo3wQr_2Im2xSojYd3NU')}
                          </Typography.Text>
                        ),
                      },
                      {
                        key: 'sync',
                        label: <Typography.Text>{t('uOk0Y1pnBVqadLng27Czb')}</Typography.Text>,
                        onClick: (ev) => {
                          ev.domEvent.stopPropagation();
                          requestHostPull();
                        },
                      },
                      {
                        key: 'refresh-key',
                        label: <Typography.Text>{t('xOdO6dKpbc9Pqi9oB539o')}</Typography.Text>,
                        onClick: (ev) => {
                          ev.domEvent.stopPropagation();
                          refreshHostKeys();
                        },
                      },
                      {
                        key: 'edit',
                        label: <Typography.Text>{t('giSlJxeyxvkwuaiGyFDji')}</Typography.Text>,
                        onClick: (ev) => {
                          ev.domEvent.stopPropagation();
                          setIsEditingHost(true);
                        },
                      },
                      {
                        key: 'delete',
                        label: <Typography.Text type="danger">{t('u7MiYLt4iCgCJmjgCy8x2')}</Typography.Text>,
                        onClick: (ev) => {
                          ev.domEvent.stopPropagation();
                          promptConfirmDelete();
                        },
                      },
                    ],
                  }}
                >
                  <Button type="default" style={{ marginRight: '.5rem' }}>
                    <SettingOutlined /> {t('hu_6X3kGb7xhCtTeCQdiH')}
                  </Button>
                </Dropdown>
              </Col>
            </Row>

            <Tabs items={hostTabs} />
          </Col>
        </Row>
      </Skeleton>

      {/* misc */}
      {notifyCtx}
      {!!host && (
        <UpdateHostModal
          key={host.id}
          isOpen={isEditingHost}
          host={host}
          onCancel={() => {
            setIsEditingHost(false);
          }}
          onUpdateHost={onUpdateHost}
        />
      )}
    </Layout.Content>
  );
}
