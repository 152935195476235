import { Network } from '@/models/Network';
import { AppRoutes } from '@/routes';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Layout, Row, Skeleton, Table, TableColumnsType, Typography } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AddNetworkModal from '../../components/modals/add-network-modal/AddNetworkModal';
import { PageProps } from '../../models/Page';
import { useStore } from '../../store/store';

import './NetworksPage.scss';
import { getNetworkRoute } from '@/utils/RouteUtils';
import { useTranslation } from 'react-i18next';

export default function NetworksPage(props: PageProps) {
  const store = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const networks = store.networks;
  const [isAddNetworkModalOpen, setIsAddNetworkModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const tableColumns: TableColumnsType<Network> = [
    {
      title: t('3WHyqtcOa34N5vK0hJtLg'),
      dataIndex: 'netid',
      key: 'netid',
      sorter: {
        compare: (a, b) => a.netid.localeCompare(b.netid),
      },
      defaultSortOrder: 'ascend',
      render: (netId) => <Link to={`${AppRoutes.NETWORKS_ROUTE}/${netId}`}>{netId}</Link>,
    },
    {
      title: t('29t3RnUdZzBw1X2x7O_2d'),
      dataIndex: 'addressrange',
      key: 'addressrange',
      render: (addressRange) => (
        <div onClick={(ev) => ev.stopPropagation()}>
          <Typography.Text>{addressRange}</Typography.Text>
        </div>
      ),
    },
    {
      title: t('zfKrX42MlxCWa2VhYDydh'),
      dataIndex: 'addressrange6',
      key: 'addressrange6',
      render: (addressRange6) => (
        <div onClick={(ev) => ev.stopPropagation()}>
          <Typography.Text>{addressRange6}</Typography.Text>
        </div>
      ),
    },
    {
      title: t('s29c_8qpcfUz0_2zuLoe'),
      render(_, network) {
        const nodeCount = store.nodes?.filter((node) => node.network === network.netid).length ?? 0;
        return (
          <div onClick={(ev) => ev.stopPropagation()}>
            <Typography.Text>{nodeCount}</Typography.Text>
          </div>
        );
      },
    },
    {
      title: t('yn7rvTkQjhOas6yyxadfy'),
      dataIndex: 'networklastmodified',
      key: 'networklastmodified',
      render: (date) => (
        <div onClick={(ev) => ev.stopPropagation()}>
          <Typography.Text>{new Date(date * 1000).toLocaleString()}</Typography.Text>
        </div>
      ),
    },
    {
      title: t('yHaNHpnj9zm6bCxtpKjK'),
      dataIndex: 'nodeslastmodified',
      key: 'nodeslastmodified',
      render: (date) => (
        <div onClick={(ev) => ev.stopPropagation()}>
          <Typography.Text>{new Date(date * 1000).toLocaleString()}</Typography.Text>
        </div>
      ),
    },
  ];

  const filteredNetworks = useMemo(
    () =>
      networks.filter((network) => {
        return network.netid.toLowerCase().includes(searchText.toLowerCase());
      }),
    [networks, searchText],
  );

  const loadNetworks = useCallback(async () => {
    await store.fetchNetworks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadNetworks();
  }, [loadNetworks]);

  return (
    <Layout.Content
      className="NetworksPage"
      style={{ position: 'relative', height: '100%', padding: props.isFullScreen ? 0 : 24 }}
    >
      <Skeleton loading={store.isFetchingNetworks} active title={true} className="page-padding">
        {networks.length === 0 && (
          <>
            <Row
              className="page-padding"
              style={{
                background: 'linear-gradient(90deg, #52379F 0%, #B66666 100%)',
              }}
            >
              <Col xs={(24 * 2) / 3}>
                <Typography.Title level={3} style={{ color: 'white ' }}>
                  {t('etLphumlcFFvQTir0am8T')}
                </Typography.Title>
                <Typography.Text style={{ color: 'white ' }}>{t('aik31hiTwt3u2JoIjV8Lg')}</Typography.Text>
              </Col>
              <Col xs={(24 * 1) / 3} style={{ position: 'relative' }}>
                <Card className="header-card" style={{ height: '20rem', position: 'absolute', width: '100%' }}>
                  <Typography.Title level={3}>{t('ww0zPk5cBDvNcusV0mIrS')}</Typography.Title>
                  <Typography.Text>{t('aCuGi0GaNUnfp4d0iM00')}</Typography.Text>
                  <Row style={{ marginTop: 'auto' }}>
                    <Col>
                      <Button type="primary" size="large" onClick={() => setIsAddNetworkModalOpen(true)}>
                        <PlusOutlined /> {t('ww0zPk5cBDvNcusV0mIrS')}
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row style={{ marginTop: '8rem', marginBottom: '4rem', padding: '0px 5.125rem' }} gutter={[0, 20]}>
              <Col xs={24}>
                <Typography.Title level={3}>{t('ww0zPk5cBDvNcusV0mIrS')}</Typography.Title>
              </Col>

              <Col xs={7} style={{ marginRight: '1rem' }}>
                <Card>
                  <Typography.Title level={4} style={{ marginTop: '0px' }}>
                    {t('sGrtOygF0aaNxo7FDpoiP')}
                  </Typography.Title>
                  <Typography.Text>
                    {t('w1dlsI9nVkJl35WeIuyTk')}{' '}
                    <a
                      href="https://www.arin.net/reference/research/statistics/address_filters/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('ofMgmndWxDk1C9TeIcB6')}
                    </a>
                    {t('rBf4qLbdgyKg5zyjm3Ok')}
                  </Typography.Text>
                </Card>
              </Col>
              <Col xs={7} style={{ marginRight: '1rem' }}>
                <Card>
                  <Typography.Title level={4} style={{ marginTop: '0px' }}>
                    {t('gLLbrBDoYsNCgbU_1fIgS')}
                  </Typography.Title>
                  <Typography.Text>{t('bDqZaKiwLAvo43iHyYiyC')}</Typography.Text>
                </Card>
              </Col>
              <Col xs={7}>
                <Card>
                  <Typography.Title level={4} style={{ marginTop: '0px' }}>
                    {t('06RxAOqe75KpDpwVykM')}
                  </Typography.Title>
                  <Typography.Text>
                    {t('trjYt9VhqJfYgoMHlts')}{' '}
                    <a href="#" target="_blank" rel="noreferrer">
                      {t('2pKnulnjZlck5AsxJo9xC')}
                    </a>{' '}
                    {t('5GrWcnLBiL2li_4LikqUh')}{' '}
                    <a href="#" target="_blank" rel="noreferrer">
                      {t('tGeJqjvgKfXzGcHl9M7y')}
                    </a>{' '}
                    {t('0Yxye_3tVdQtEvV8OzDbr')}{' '}
                    <a href="#" target="_blank" rel="noreferrer">
                      {t('jn4TXlb6oDVwRxq2vIi')}
                    </a>{' '}
                    {t('pAXcax5K5Y3DOfVqe9hAh')}
                  </Typography.Text>
                </Card>
                {/* <Card>
                  <Typography.Title level={4} style={{ marginTop: '0px' }}>
                    {t('06RxAOqe75KpDpwVykM')}
                  </Typography.Title>
                  <Typography.Text>
                    {t('trjYt9VhqJfYgoMHlts')}{' '}
                    <a href="https://www.netmaker.io/features/ingress" target="_blank" rel="noreferrer">
                      {t('2pKnulnjZlck5AsxJo9xC')}
                    </a>{' '}
                    {t('5GrWcnLBiL2li_4LikqUh')}{' '}
                    <a href="https://www.netmaker.io/features/egress" target="_blank" rel="noreferrer">
                      {t('tGeJqjvgKfXzGcHl9M7y')}
                    </a>{' '}
                    {t('0Yxye_3tVdQtEvV8OzDbr')}{' '}
                    <a href="https://www.netmaker.io/features/acls" target="_blank" rel="noreferrer">
                      {t('jn4TXlb6oDVwRxq2vIi')}
                    </a>{' '}
                    {t('pAXcax5K5Y3DOfVqe9hAh')}
                  </Typography.Text>
                </Card> */}
              </Col>
            </Row>
          </>
        )}
        {networks.length > 0 && (
          <>
            <Row className="page-row-padding">
              <Col xs={24}>
                <Typography.Title level={3}>{t('etLphumlcFFvQTir0am8T')}</Typography.Title>
              </Col>
            </Row>

            <Row className="page-row-padding" justify="space-between">
              <Col xs={12} md={8}>
                <Input
                  size="large"
                  placeholder={t('qbStWf7zGgfuBZeSriLo2')}
                  value={searchText}
                  onChange={(ev) => setSearchText(ev.target.value)}
                  prefix={<SearchOutlined />}
                />
              </Col>
              <Col xs={12} md={6} style={{ textAlign: 'right' }}>
                <Button type="primary" size="large" onClick={() => setIsAddNetworkModalOpen(true)}>
                  <PlusOutlined /> {t('yrw4jg0T3q4rt4jIl99Bf')}
                </Button>
              </Col>
            </Row>

            <Row className="page-row-padding" justify="space-between">
              <Col xs={24}>
                <Table
                  columns={tableColumns}
                  dataSource={filteredNetworks}
                  rowKey="netid"
                  onRow={(network) => {
                    return {
                      onClick: () => {
                        navigate(getNetworkRoute(network));
                      },
                    };
                  }}
                />
              </Col>
            </Row>
          </>
        )}
      </Skeleton>

      {/* modals */}
      <AddNetworkModal
        isOpen={isAddNetworkModalOpen}
        onCreateNetwork={() => {
          setIsAddNetworkModalOpen(false);
        }}
        onCancel={() => setIsAddNetworkModalOpen(false)}
      />
    </Layout.Content>
  );
}
