import { AppRoutes } from '@/routes';
import { AuthService } from '@/services/AuthService';
import { LoginDto } from '@/services/dtos/LoginDto';
import { useStore } from '@/store/store';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Input, Layout, notification, Row, Typography } from 'antd';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AMUI_URL, isSaasBuild } from '../../services/BaseService';
import { extractErrorMsg } from '@/utils/ServiceUtils';
import { UsersService } from '@/services/UsersService';
import { User } from '@/models/User';
import { ApiRoutes } from '@/constants/ApiRoutes';
import { truncateQueryParamsFromCurrentUrl, useQuery } from '@/utils/RouteUtils';
import { AppErrorBoundary } from '@/components/AppErrorBoundary';

interface LoginPageProps {
  isFullScreen?: boolean;
}

export default function LoginPage(props: LoginPageProps) {
  const [form] = Form.useForm<LoginDto>();
  const [notify, notifyCtx] = notification.useNotification();
  const store = useStore();
  const storeFetchServerConfig = store.fetchServerConfig;
  const navigate = useNavigate();
  const { backend, token } = useParams();
  const { t } = useTranslation();
  const query = useQuery();
  const location = useLocation();

  const oauthToken = query.get('login');
  const oauthUser = query.get('user');
  const [shouldRemember, setShouldRemember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getUserAndUpdateInStore = async (username: User['username']) => {
    try {
      const user = await (await UsersService.getUser(username)).data;
      store.setStore({ user });
    } catch (err) {
      notify.error({ message: 'Failed to get user details', description: extractErrorMsg(err as any) });
    }
  };

  const onLogin = async () => {
    try {
      const formData = await form.validateFields();
      setIsLoading(true);
      const data = await (await AuthService.login(formData)).data;
      store.setStore({ jwt: data.Response.AuthToken, username: data.Response.UserName });
      await storeFetchServerConfig();
      await getUserAndUpdateInStore(data.Response.UserName);
    } catch (err) {
      notify.error({ message: t('xTw0NYph6lpfohUdSTyw'), description: extractErrorMsg(err as any) });
    } finally {
      setIsLoading(false);
    }
  };

  const checkIfServerHasAdminAndRedirect = useCallback(async () => {
    const hasAdmin = (await UsersService.serverHasAdmin()).data;
    if (!hasAdmin) navigate(AppRoutes.SIGNUP_ROUTE);
  }, [navigate]);

  useEffect(() => {
    checkIfServerHasAdminAndRedirect();
  }, [checkIfServerHasAdminAndRedirect]);

  if (isSaasBuild) {
    if (!backend && !token) {
      window.location.href = AMUI_URL;
      return null;
    }
    store.setStore({ jwt: token, baseUrl: backend });
    truncateQueryParamsFromCurrentUrl();
    // TODO: load username
    navigate(AppRoutes.DASHBOARD_ROUTE);
    return null;
  } else {
    if (oauthToken) {
      store.setStore({ jwt: oauthToken, username: oauthUser ?? undefined });
      if (oauthUser) {
        getUserAndUpdateInStore(oauthUser);
      }
      truncateQueryParamsFromCurrentUrl();
      navigate(AppRoutes.DASHBOARD_ROUTE);
      return null;
    }
  }

  if (store.isLoggedIn()) {
    navigate(AppRoutes.DASHBOARD_ROUTE);
  }

  return (
    <AppErrorBoundary key={location.pathname}>
      <Layout style={{ height: '100%', minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>
        <Layout.Content
          style={{
            marginTop: '15vh',
            position: 'relative',
            height: 'fit-content',
            width: '40%',
            padding: props.isFullScreen ? 0 : 24,
          }}
        >
          <Row>
            <Col xs={24}>
              <Typography.Title level={2}>{t('signin.signin')}</Typography.Title>
            </Col>
          </Row>
          <Form
            form={form}
            layout="vertical"
            onKeyUp={(ev) => {
              if (ev.key === 'Enter') {
                onLogin();
              }
            }}
          >
            <Form.Item name="username" label={t('signin.username')} rules={[{ required: true }]}>
              <Input placeholder={String(t('signin.username'))} size="large" prefix={<MailOutlined />} />
            </Form.Item>
            <Form.Item name="password" label={t('signin.password')} rules={[{ required: true }]}>
              <Input
                placeholder={String(t('signin.password'))}
                type="password"
                size="large"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <Row style={{ marginBottom: '1.5rem' }}>
              <Col>
                <Checkbox checked={shouldRemember} onChange={(e) => setShouldRemember(e.target.checked)}>
                  {' '}
                  <Typography.Text>{t('signin.rememberme')}</Typography.Text>
                </Checkbox>
              </Col>
            </Row>

            <Form.Item style={{ marginTop: '1.5rem' }}>
              <Button type="primary" block onClick={onLogin} loading={isLoading}>
                {t('signin.signin')}
              </Button>
            </Form.Item>
          </Form>
        </Layout.Content>

        {/* misc */}
        {notifyCtx}
      </Layout>
    </AppErrorBoundary>
  );
}
