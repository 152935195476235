import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import { MouseEvent } from 'react';
import { useStore } from '@/store/store';
import '../CustomModal.scss';
import { extractErrorMsg } from '@/utils/ServiceUtils';
import { Host } from '@/models/Host';
import { Node } from '@/models/Node';
import { getHostRoute } from '@/utils/RouteUtils';
import { NodesService } from '@/services/NodesService';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { NODE_EXP_TIME_FORMAT } from '@/constants/AppConstants';
import { useTranslation } from 'react-i18next';

interface UpdateNodeModalProps {
  isOpen: boolean;
  node: Node;
  onUpdateNode: (node: Node) => any;
  onCancel?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export default function UpdateNodeModal({ isOpen, node, onUpdateNode, onCancel }: UpdateNodeModalProps) {
  const [form] = Form.useForm<Node>();
  const store = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const storeUpdateNode = store.updateNode;
  const isStaticVal: Host['isstatic'] = Form.useWatch('isstatic', form);

  const network = store.networks.find((n) => n.netid === node.network);
  const host = store.hosts.find((h) => h.id === node.hostid);

  const resetModal = () => {
    form.resetFields();
  };

  const updateNode = async () => {
    try {
      const formData = await form.validateFields();
      const newNode = (
        await NodesService.updateNode(node.id, node.network, {
          ...node,
          ...formData,
          expdatetime: Math.floor(new Date(formData.expdatetime).getTime() / 1000),
        })
      ).data;
      notification.success({ message: t('gitb7lrz7HkCo5JorBfQ', { hostId: node.id }) });
      storeUpdateNode(newNode.id, newNode);
      onUpdateNode(newNode);
    } catch (err) {
      notification.error({
        message: t('kSuTupoZxE9CFmIbxkRd'),
        description: extractErrorMsg(err as any),
      });
    }
  };

  const disabledDateTime = () => ({
    disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i + 1),
  });

  return (
    <Modal
      title={
        <Typography.Title style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
          {t('zHaYnsvgDHexJu_0TUib')}
          <br />
          <small>(ID: {node.id})</small>
        </Typography.Title>
      }
      open={isOpen}
      onCancel={(ev) => {
        resetModal();
        onCancel?.(ev);
      }}
      footer={null}
      centered
      className="CustomModal"
    >
      <Divider style={{ margin: '0px 0px 2rem 0px' }} />
      <Form
        name="update-node-form"
        form={form}
        layout="vertical"
        initialValues={{ ...node, expdatetime: dayjs(node.expdatetime * 1000), endpointip: host?.endpointip ?? '' }}
      >
        <div className="scrollable-modal-body">
          <div className="CustomModalBody">
            <Form.Item
              label={t('4XnrVeasDVxXy2OSm7lx9')}
              name="address"
              rules={[{ required: true }]}
              data-nmui-intercom="update-node-form_address"
            >
              <Input placeholder={t('bpuEhHvjC6efTblUx7Qba')} />
            </Form.Item>

            {network?.isipv6 && (
              <Form.Item
                label={t('zNyyau58tDn6bdDkyaPoV')}
                name="address6"
                rules={[{ required: true }]}
                data-nmui-intercom="update-node-form_address6"
              >
                <Input placeholder={t('gw3S1vBgca4EtIs4QqZmZ')} />
              </Form.Item>
            )}

            <Form.Item
              label={t('pTwXnKCui0pBsze2dZvN2')}
              name="localaddress"
              data-nmui-intercom="update-node-form_localaddress"
            >
              <Select
                placeholder={t('tIlM2wshdPx2Z0S5Djyk')}
                options={
                  host?.interfaces?.map((iface) => ({
                    label: `${iface.name} (${iface.addressString})`,
                    value: iface.addressString,
                  })) ?? []
                }
              />
            </Form.Item>

            <Form.Item
              label={t('g43DqjhLgAnIb39peOsl')}
              name="persistentkeepalive"
              rules={[{ required: true }]}
              data-nmui-intercom="update-node-form_persistentkeepalive"
            >
              <InputNumber placeholder={t('kpjUmZv2LPnYzySkryLy')} min={0} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              label={t('2WixevMk89kTfixUf0X0A')}
              name="expdatetime"
              rules={[{ required: true }]}
              data-nmui-intercom="update-node-form_expdatetime"
            >
              <DatePicker
                showTime={true}
                disabledTime={disabledDateTime}
                style={{ width: '100%' }}
                clearIcon={false}
                format={NODE_EXP_TIME_FORMAT}
              />
            </Form.Item>

            <Form.Item
              label={t('q6gDvIn4RtXVpPKecBk7')}
              name="endpointip"
              rules={[{ required: isStaticVal }]}
              data-nmui-intercom="update-node-form_endpointip"
            >
              <Input
                placeholder={t('q6gDvIn4RtXVpPKecBk7')}
                disabled={!isStaticVal}
                title={t('yUpKe2wLc2H6xd9BcIv6S')}
              />
            </Form.Item>

            <Form.Item
              label={t('w5pQogyclQendamx1ylLu')}
              name="defaultacl"
              rules={[{ required: true }]}
              data-nmui-intercom="update-node-form_defaultacl"
            >
              <Select
                placeholder={t('nZm88wYjeLoWGWlbKboL')}
                options={[
                  { label: t('ohwInYvUkd7X0kkzsqs4d'), value: 'yes' },
                  { label: t('4VbHaEcWqKqpkNvyo2S4'), value: 'no' },
                  { label: t('acEAmVoRpoGXYvUDcB'), value: 'unset' },
                ]}
              />
            </Form.Item>

            <Form.Item
              label={t('aBbehXc5a4YyYnu7zwXu')}
              name="connected"
              valuePropName="checked"
              rules={[{ required: true }]}
              data-nmui-intercom="update-node-form_connected"
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label={t('2uMUtN1WfMcJ6X0Goktsy')}
              name="dnson"
              valuePropName="checked"
              rules={[{ required: true }]}
              data-nmui-intercom="update-node-form_dnson"
            >
              <Switch />
            </Form.Item>
          </div>
        </div>
        <Divider style={{ margin: '0px 0px 2rem 0px' }} />

        <div className="CustomModalBody">
          <Row>
            <Col xs={12}>
              <Form.Item noStyle data-nmui-intercom="update-node-form_editglobalhost">
                <Button onClick={() => navigate(getHostRoute(node.hostid, { edit: 'true' }))}>
                  {t('hlVbWhZrVflvl10McHwl')}
                </Button>
              </Form.Item>
            </Col>
            <Col xs={12} style={{ textAlign: 'right' }}>
              <Form.Item noStyle data-nmui-intercom="update-node-form_submit">
                <Button type="primary" onClick={updateNode}>
                  {t('nx3vh5_81ZbrYiCaCEib')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
}
