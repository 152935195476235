import '../CustomModal.scss';
import { Col, Divider, Modal, Row, Tag, Typography } from 'antd';
import { EnrollmentKey, TimeBoundEnrollmentKey, UsesBasedEnrollmentKey } from '@/models/EnrollmentKey';
import { MouseEvent } from 'react';
import { isEnrollmentKeyValid, renderEnrollmentKeyType } from '@/utils/EnrollmentKeysUtils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface EnrollmentKeyDetailsModalProps {
  isOpen: boolean;
  enrollmentKey: EnrollmentKey;
  onCancel?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export default function EnrollmentKeyDetailsModal({ isOpen, enrollmentKey, onCancel }: EnrollmentKeyDetailsModalProps) {
  const shouldShowExpDate =
    enrollmentKey.type === TimeBoundEnrollmentKey ||
    (enrollmentKey.uses_remaining === 0 && enrollmentKey.unlimited === false);
  const { t } = useTranslation();

  return (
    <Modal
      title={<span style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{t('xQoZZnptzfB5rpsk7cT2')}</span>}
      open={isOpen}
      footer={null}
      centered
      className="EnrollmentKeyDetailsModal CustomModal"
      onCancel={onCancel}
    >
      <Divider style={{ margin: '0px 0px 2rem 0px' }} />
      <div className="CustomModalBody">
        <Row data-nmui-intercom="enrollment-key-details_name">
          <Col xs={8}>
            <Typography.Text>{t('3WHyqtcOa34N5vK0hJtLg')}</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text>{enrollmentKey.tags.join(', ')}</Typography.Text>
            <Tag
              style={{ marginLeft: '1rem' }}
              color={isEnrollmentKeyValid(enrollmentKey) ? t('7Yt7hLd6lLkRPbPKwGq') : t('qOg7UgHFypGxOahVWqPv')}
            >
              {isEnrollmentKeyValid(enrollmentKey) ? 'Valid' : 'Invalid'}
            </Tag>
          </Col>
        </Row>
        <Divider style={{ margin: '1rem 0px 1rem 0px' }} />
        <Row data-nmui-intercom="enrollment-key-details_token">
          <Col xs={8}>
            <Typography.Text>{t('tdTgD4bzQvwl2qdmYs5Aj')}</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text>{enrollmentKey.token}</Typography.Text>
          </Col>
        </Row>
        <Divider style={{ margin: '1rem 0px 1rem 0px' }} />
        <Row data-nmui-intercom="enrollment-key-details_type">
          <Col xs={8}>
            <Typography.Text>{t('mgkwATrsvWuzqqxWz0oeR')}</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text>{renderEnrollmentKeyType(enrollmentKey.type)}</Typography.Text>
          </Col>
        </Row>
        <Divider style={{ margin: '1rem 0px 1rem 0px' }} />
        <Row data-nmui-intercom="enrollment-key-details_unlimited">
          <Col xs={8}>
            <Typography.Text>{t('p3AfYiFIwz5lemg90Bj2U')}</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text>{enrollmentKey.unlimited ? 'Yes' : 'No'}</Typography.Text>
          </Col>
        </Row>
        <Divider style={{ margin: '1rem 0px 1rem 0px' }} />
        <Row data-nmui-intercom="enrollment-key-details_usesremaining">
          <Col xs={8}>
            <Typography.Text>{t('fMtbo52o0at2WlGuPfmIm')}</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text>
              {enrollmentKey.type === UsesBasedEnrollmentKey
                ? enrollmentKey.uses_remaining
                : t('yoIumlXbavdJen_5cjLsQ')}
            </Typography.Text>
          </Col>
        </Row>
        <Divider style={{ margin: '1rem 0px 1rem 0px' }} />
        <Row data-nmui-intercom="enrollment-key-details_expiration">
          <Col xs={8}>
            <Typography.Text>{t('oHcmLcUstyZgpGi1ZMrvg')}</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text>
              {shouldShowExpDate ? dayjs(enrollmentKey.expiration).toLocaleString() : 'never'}
            </Typography.Text>
          </Col>
        </Row>
        <Divider style={{ margin: '1rem 0px 1rem 0px' }} />
        <Row data-nmui-intercom="enrollment-key-details_networks">
          <Col xs={8}>
            <Typography.Text>{t('etLphumlcFFvQTir0am8T')}</Typography.Text>
          </Col>
          <Col xs={16}>
            <Typography.Text>{enrollmentKey.networks.join(', ')}</Typography.Text>
          </Col>
        </Row>
        <Divider style={{ margin: '1rem 0px 1rem 0px' }} />
        <Row data-nmui-intercom="enrollment-key-details_joincmd">
          <Col xs={24}>
            <Typography.Text type="secondary">{t('bdZinKVeBvoeZlYtcPqg')}</Typography.Text>
            <br />
            <Typography.Text copyable code>{`netclient join -t ${enrollmentKey.token}`}</Typography.Text>
          </Col>
        </Row>
        <Divider style={{ margin: '1rem 0px 1rem 0px' }} />
      </div>
    </Modal>
  );
}
