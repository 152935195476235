import { Button, Col, Divider, Form, Input, Modal, notification, Row, Switch } from 'antd';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useStore } from '@/store/store';
import '../CustomModal.scss';
import { extractErrorMsg } from '@/utils/ServiceUtils';
import { User } from '@/models/User';
import { UsersService } from '@/services/UsersService';
import { useTranslation } from 'react-i18next';

interface AddUserModalProps {
  isOpen: boolean;
  onCreateUser: (user: User) => any;
  onCancel?: (e: MouseEvent<HTMLButtonElement>) => void;
}

type CreateUserForm = User & { password: string; 'confirm-password': string };

export default function AddUserModal({ isOpen, onCreateUser, onCancel }: AddUserModalProps) {
  const [form] = Form.useForm<CreateUserForm>();
  const [notify, notifyCtx] = notification.useNotification();
  const store = useStore();
  const { t } = useTranslation();

  const isServerEE = store.serverConfig?.IsEE === 'yes';
  const [users, setUsers] = useState<User[]>([]);
  const isAdminVal = Form.useWatch('isadmin', form);
  const passwordVal = Form.useWatch('password', form);

  const resetModal = () => {
    form.resetFields();
  };

  const createUser = async () => {
    try {
      const formData = await form.validateFields();
      if (isAdminVal) {
        formData.networks = [];
      }
      if (!isServerEE) {
        formData.groups = ['*'];
      }
      const newUser = (await UsersService.createUser(formData)).data;
      resetModal();
      notify.success({ message: t('fIy3zDWsApCGbfELknqs', { username: newUser.username }) });
      onCreateUser(newUser);
    } catch (err) {
      notify.error({
        message: t('jC5pvUxnB9o9H0Rm2Ksas'),
        description: extractErrorMsg(err as any),
      });
    }
  };

  const loadUsers = useCallback(async () => {
    try {
      const users = (await UsersService.getUsers()).data;
      setUsers(users);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (isAdminVal) {
      form.setFieldsValue({ networks: [] });
    }
  }, [form, isAdminVal]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <Modal
      title={<span style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{t('mJiLzEiyNceuc5eJq8Fij')}</span>}
      open={isOpen}
      onCancel={(ev) => {
        resetModal();
        onCancel?.(ev);
      }}
      footer={null}
      centered
      className="CustomModal"
    >
      <Divider style={{ margin: '0px 0px 2rem 0px' }} />
      <div className="CustomModalBody">
        <Form name="add-user-form" form={form} layout="vertical" initialValues={{ groups: ['*'], isadmin: true }}>
          <Form.Item label={t('mrEPg3ShMqu0tZiLic8t9')} name="username" rules={[{ required: true }]}>
            <Input placeholder={t('mrEPg3ShMqu0tZiLic8t9')} />
          </Form.Item>

          <Form.Item label={t('u9yz2s9lGFaA9OvLKvQpU')} name="password" rules={[{ required: true }]}>
            <Input placeholder={t('u9yz2s9lGFaA9OvLKvQpU')} type="password" />
          </Form.Item>

          <Form.Item
            label={t('fMqBtdAo34ru03IcTr_2m')}
            name="confirm-password"
            rules={[
              { required: true, message: '' },
              {
                validator(_, value) {
                  if (value !== passwordVal) {
                    return Promise.reject(t('pe4Xv71lM7yjSoQRqmQaq'));
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
            dependencies={['password']}
          >
            <Input placeholder={t('fMqBtdAo34ru03IcTr_2m')} type="password" />
          </Form.Item>

          <Form.Item label={t('uzIG5OLerLeK6Gbj8Cr')} name="isadmin" valuePropName="checked">
            <Switch
              disabled
              onChange={(newVal) => {
                if (newVal) {
                  form.setFieldValue('networks', []);
                }
              }}
            />
          </Form.Item>

          <Row>
            <Col xs={24} style={{ textAlign: 'right' }}>
              <Form.Item>
                <Button type="primary" onClick={createUser}>
                  {t('jhlLyeTr3WXkgzNsjx3x')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      {/* notify */}
      {notifyCtx}
    </Modal>
  );
}
