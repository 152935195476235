import AddClientModal from '@/components/modals/add-client-modal/AddClientModal';
import AddDnsModal from '@/components/modals/add-dns-modal/AddDnsModal';
import AddEgressModal from '@/components/modals/add-egress-modal/AddEgressModal';
import AddRelayModal from '@/components/modals/add-relay-modal/AddRelayModal';
import ClientDetailsModal from '@/components/modals/client-detaiils-modal/ClientDetailsModal';
import UpdateEgressModal from '@/components/modals/update-egress-modal/UpdateEgressModal';
import { ACL_ALLOWED, ACL_DENIED, AclStatus, NodeAcl, NodeAclContainer } from '@/models/Acl';
import { DNS } from '@/models/Dns';
import { ExtClientAcls, ExternalClient } from '@/models/ExternalClient';
import { Host } from '@/models/Host';
import { Network } from '@/models/Network';
import { ExtendedNode, Node } from '@/models/Node';
import { AppRoutes } from '@/routes';
import { HostsService } from '@/services/HostsService';
import { NetworksService } from '@/services/NetworksService';
import { NodesService } from '@/services/NodesService';
import { useStore } from '@/store/store';
import { getExtendedNode, isNodeRelay } from '@/utils/NodeUtils';
import { getNetworkHostRoute } from '@/utils/RouteUtils';
import { extractErrorMsg } from '@/utils/ServiceUtils';
import {
  CheckOutlined,
  CloseOutlined,
  DashOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  // DownloadOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
  MoreOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  SettingOutlined,
  StopOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  MenuProps,
  Modal,
  notification,
  Progress,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Switch,
  Table,
  TableColumnProps,
  Tabs,
  TabsProps,
  Tag,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageProps } from '../../models/Page';
import '@react-sigma/core/lib/react-sigma.min.css';
import './NetworkDetailsPage.scss';
import { ControlsContainer, FullScreenControl, SearchControl, SigmaContainer, ZoomControl } from '@react-sigma/core';
import NetworkGraph from '@/components/NetworkGraph';
import UpdateRelayModal from '@/components/modals/update-relay-modal/UpdateRelayModal';
import { MetricCategories, NetworkMetrics, NodeOrClientMetric, UptimeNodeMetrics } from '@/models/Metrics';
import { getExtClientAclStatus, getHostHealth, getTimeMinHrs, renderMetricValue } from '@/utils/Utils';
import AddHostsToNetworkModal from '@/components/modals/add-hosts-to-network-modal/AddHostsToNetworkModal';
import NewHostModal from '@/components/modals/new-host-modal/NewHostModal';
import AddIngressModal from '@/components/modals/add-ingress-modal/AddIngressModal';
import UpdateIngressModal from '@/components/modals/update-ingress-modal/UpdateIngressModal';
import UpdateClientModal from '@/components/modals/update-client-modal/UpdateClientModal';
import { NULL_HOST, NULL_NODE } from '@/constants/Types';
import UpdateNodeModal from '@/components/modals/update-node-modal/UpdateNodeModal';
import { getBrandingConfig } from '@/services/BaseService';
import VirtualisedTable from '@/components/VirtualisedTable';
import { NETWORK_GRAPH_SIGMA_CONTAINER_ID } from '@/constants/AppConstants';
import { useTranslation } from 'react-i18next';

interface ExternalRoutesTableData {
  node: ExtendedNode;
  range: Node['egressgatewayranges'][0];
}

interface AclTableData {
  type: 'node' | 'client';
  nodeOrClientId: Node['id'] | ExternalClient['clientid'];
  name: Host['name'] | ExternalClient['clientid'];
  acls?: NodeAcl;
  clientAcls?: ExtClientAcls;
}

interface NodeMetricsTableData {
  nodeId: Node['id'];
  nodeName: ExtendedNode['name'];
  connectivity?: {
    [nodeId: string]: boolean;
  };
  latency?: {
    [nodeId: string]: number;
  };
  bytesSent?: {
    [nodeId: string]: number;
  };
  bytesReceived?: {
    [nodeId: string]: number;
  };
  uptime?: {
    [nodeId: string]: UptimeNodeMetrics;
  };
}

export default function NetworkDetailsPage(props: PageProps) {
  const { networkId } = useParams<{ networkId: string }>();
  const { t } = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const [notify, notifyCtx] = notification.useNotification();
  const { token: themeToken } = theme.useToken();

  const storeFetchNodes = store.fetchNodes;
  const storeDeleteNode = store.deleteNode;
  const isServerEE = store.serverConfig?.IsEE === 'yes';
  const [form] = Form.useForm<Network>();
  const isIpv4Watch = Form.useWatch('isipv4', form);
  const isIpv6Watch = Form.useWatch('isipv6', form);
  const [network, setNetwork] = useState<Network | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingNetwork, setIsEditingNetwork] = useState(false);
  const [searchHost, setSearchHost] = useState('');
  const [searchDns, setSearchDns] = useState('');
  const [dnses, setDnses] = useState<DNS[]>([]);
  const [isAddDnsModalOpen, setIsAddDnsModalOpen] = useState(false);
  const [nodeAcls, setNodeAcls] = useState<NodeAclContainer>({});
  const [originalNodeAcls, setOriginalNodeAcls] = useState<NodeAclContainer>({});
  const [clientAcls, setClientAcls] = useState<Record<ExternalClient['clientid'], ExtClientAcls>>({});
  const [originalClientAcls, setOriginalClientAcls] = useState<Record<ExternalClient['clientid'], ExtClientAcls>>({});
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
  const [clients, setClients] = useState<ExternalClient[]>([]);
  const [isClientDetailsModalOpen, setIsClientDetailsModalOpen] = useState(false);
  const [targetClient, setTargetClient] = useState<ExternalClient | null>(null);
  const [selectedGateway, setSelectedGateway] = useState<Node | null>(null);
  const [searchClientGateways, setSearchClientGateways] = useState('');
  const [searchClients, setSearchClients] = useState('');
  const [filteredEgress, setFilteredEgress] = useState<Node | null>(null);
  const [isAddEgressModalOpen, setIsAddEgressModalOpen] = useState(false);
  const [searchEgress, setSearchEgress] = useState('');
  const [isUpdateEgressModalOpen, setIsUpdateEgressModalOpen] = useState(false);
  const [selectedRelay, setSelectedRelay] = useState<ExtendedNode | null>(null);
  const [isAddRelayModalOpen, setIsAddRelayModalOpen] = useState(false);
  const [searchRelay, setSearchRelay] = useState('');
  const [isUpdateRelayModalOpen, setIsUpdateRelayModalOpen] = useState(false);
  const [searchAclHost, setSearchAclHost] = useState('');
  // const [isDownloadingMetrics, setIsDownloadingMetrics] = useState(false);
  const [currentMetric, setCurrentMetric] = useState<MetricCategories>('connectivity-status');
  const [networkNodeMetrics, setNetworkNodeMetrics] = useState<NetworkMetrics | null>(null);
  const [clientMetrics, setClientMetrics] = useState<Record<ExternalClient['clientid'], NodeOrClientMetric> | null>(
    null,
  );
  const [filteredMetricNodeId, setFilteredMetricNodeId] = useState<Node['id'] | null>(null);
  const [isAddHostsToNetworkModalOpen, setIsAddHostsToNetworkModalOpen] = useState(false);
  const [isAddNewHostModalOpen, setIsAddNewHostModalOpen] = useState(false);
  const [isAddClientGatewayModalOpen, setIsAddClientGatewayModalOpen] = useState(false);
  const [isUpdateGatewayModalOpen, setIsUpdateGatewayModalOpen] = useState(false);
  const [isUpdateClientModalOpen, setIsUpdateClientModalOpen] = useState(false);
  const [isUpdateNodeModalOpen, setIsUpdateNodeModalOpen] = useState(false);
  const [targetNode, setTargetNode] = useState<Node | null>(null);
  const [showClientAcls, setShowClientAcls] = useState(false);
  const [isSubmittingAcls, setIsSubmittingAcls] = useState(false);

  const networkNodes = useMemo(
    () =>
      store.nodes
        .map((node) => getExtendedNode(node, store.hostsCommonDetails))
        .filter((node) => node.network === networkId)
        .filter((node) => `${node?.name ?? ''}${node.address}`.toLowerCase().includes(searchHost.toLowerCase())),
    [store.nodes, store.hostsCommonDetails, networkId, searchHost],
  );

  const clientGateways = useMemo<ExtendedNode[]>(() => {
    return networkNodes
      .filter((node) => node.isingressgateway)
      .map((node) => getExtendedNode(node, store.hostsCommonDetails));
  }, [networkNodes, store.hostsCommonDetails]);

  const filteredClientGateways = useMemo<ExtendedNode[]>(
    () =>
      clientGateways.filter((node) => node.name?.toLowerCase().includes(searchClientGateways.toLowerCase()) ?? false),
    [clientGateways, searchClientGateways],
  );

  const filteredClients = useMemo<ExternalClient[]>(
    () =>
      clients
        .filter((client) => {
          if (selectedGateway) {
            return client.ingressgatewayid === selectedGateway.id;
          }
          const filteredGatewayIds = filteredClientGateways.map((node) => node.id);
          return filteredGatewayIds.includes(client.ingressgatewayid);
        })
        .filter((client) => client.clientid?.toLowerCase().includes(searchClients.toLowerCase()) ?? false)
        .sort((a, b) => a.ingressgatewayid.localeCompare(b.ingressgatewayid)),
    [clients, filteredClientGateways, searchClients, selectedGateway],
  );

  const egresses = useMemo<ExtendedNode[]>(() => {
    return networkNodes
      .filter((node) => node.isegressgateway)
      .map((node) => getExtendedNode(node, store.hostsCommonDetails));
  }, [networkNodes, store.hostsCommonDetails]);

  const filteredEgresses = useMemo<ExtendedNode[]>(
    () => egresses.filter((egress) => egress.name?.toLowerCase().includes(searchEgress.toLowerCase()) ?? false),
    [egresses, searchEgress],
  );

  const filteredExternalRoutes = useMemo<ExternalRoutesTableData[]>(() => {
    if (filteredEgress) {
      return filteredEgress.egressgatewayranges.map((range) => ({
        node: getExtendedNode(filteredEgress, store.hostsCommonDetails),
        range,
      }));
    } else {
      return filteredEgresses
        .flatMap((e) => e.egressgatewayranges.map((range) => ({ node: e, range })))
        .sort((a, b) => a.node.id.localeCompare(b.node.id));
    }
  }, [filteredEgress, filteredEgresses, store.hostsCommonDetails]);

  const networkHosts = useMemo(() => {
    const hostsMap = new Map<Host['id'], Host>();
    store.hosts.forEach((host) => {
      hostsMap.set(host.id, host);
    });
    return store.nodes
      .filter((node) => node.network === networkId)
      .map((node) => hostsMap.get(node.hostid) ?? NULL_HOST);
  }, [networkId, store.hosts, store.nodes]);

  const relays = useMemo<ExtendedNode[]>(() => {
    if (!isServerEE) {
      return [];
    }
    return networkNodes.filter((node) => isNodeRelay(node));
  }, [networkNodes, isServerEE]);

  const filteredRelays = useMemo<ExtendedNode[]>(
    () => relays.filter((relay) => relay.name?.toLowerCase().includes(searchRelay.toLowerCase()) ?? false),
    [relays, searchRelay],
  );

  const filteredRelayedNodes = useMemo<ExtendedNode[]>(() => {
    if (selectedRelay) {
      return networkNodes.filter((node) => node.relayedby === selectedRelay.id);
    } else {
      return networkNodes.filter((node) => node.relayedby).sort((a, b) => a.relayedby.localeCompare(b.relayedby));
    }
  }, [networkNodes, selectedRelay]);

  const toggleClientStatus = useCallback(
    async (client: ExternalClient, newStatus: boolean) => {
      if (!networkId) return;
      Modal.confirm({
        title: t('81XbnbyDtImCBmIFluJv', {
          status: newStatus ? t('aQliv5H2LqWFbqOCbTd') : t('bvbSkVcljzOVtKv_3uZ6M'),
          client: client.clientid,
        }),
        content: t('nWbkPnEjG4p6A39sb7mo', {
          client: client.clientid,
          status: newStatus ? t('jbEr67Tp9fKWemfXJk') : t('jiKfeLn5DgxtytEXeZ3G4'),
        }),
        onOk: async () => {
          try {
            const newClient = (
              await NodesService.updateExternalClient(client.clientid, networkId, {
                ...client,
                clientid: client.clientid,
                enabled: newStatus,
              })
            ).data;
            setClients((prev) => prev.map((c) => (c.clientid === newClient.clientid ? newClient : c)));
          } catch (err) {
            notify.error({
              message: t('uq0rVBkD97WTt0vFQnB4'),
              description: extractErrorMsg(err as any),
            });
          }
        },
      });
    },
    [networkId, notify],
  );

  const networkAcls = useMemo(() => {
    const networkAcls: NodeAclContainer = {};
    const networkNodesMap = new Map<Node['id'], boolean>();
    networkNodes.forEach((node) => {
      networkNodesMap.set(node.id, true);
    });
    Object.keys(nodeAcls).forEach((nodeId) => {
      if (networkNodesMap.has(nodeId)) {
        networkAcls[nodeId] = nodeAcls[nodeId];
      }
    });
    return networkAcls;
  }, [nodeAcls, networkNodes]);

  const aclTableData = useMemo<AclTableData[]>(() => {
    // node acls
    const aclDataPerNode: AclTableData[] = networkNodes
      .map((node) => getExtendedNode(node, store.hostsCommonDetails))
      .map((node) => ({
        type: 'node',
        nodeOrClientId: node.id,
        name: node?.name ?? '',
        acls: networkAcls[node.id],
      }));

    // client acls
    if (showClientAcls) {
      clients.forEach((client) => {
        aclDataPerNode.push({
          type: 'client',
          nodeOrClientId: client.clientid,
          name: client.clientid,
          clientAcls: clientAcls[client.clientid],
        });
      });
    }

    aclDataPerNode.sort((a, b) => a?.name?.localeCompare(b?.name ?? '') ?? 0);
    return aclDataPerNode;
  }, [clientAcls, clients, networkAcls, networkNodes, showClientAcls, store.hostsCommonDetails]);

  const filteredAclData = useMemo<AclTableData[]>(() => {
    return aclTableData.filter((node) => node.name.toLowerCase().includes(searchAclHost.toLowerCase()));
  }, [aclTableData, searchAclHost]);

  const connectivityStatusMetricsData = useMemo<NodeMetricsTableData[]>(() => {
    return Object.keys(networkNodeMetrics?.nodes ?? {}).map((nodeId) => {
      const nodeConnectivityMap = networkNodeMetrics?.nodes[nodeId].connectivity;
      const res = {
        nodeId: nodeId,
        nodeName: networkNodeMetrics?.nodes[nodeId].node_name ?? '',
        connectivity: {} as NodeMetricsTableData['connectivity'],
      };
      Object.keys(nodeConnectivityMap ?? {}).reduce((acc, key) => {
        acc.connectivity![key] = nodeConnectivityMap?.[key].connected ?? false;
        return acc;
      }, res);
      return res;
    });
  }, [networkNodeMetrics?.nodes]);

  const latencyMetricsData = useMemo<NodeMetricsTableData[]>(() => {
    return Object.keys(networkNodeMetrics?.nodes ?? {}).map((nodeId) => {
      const nodeConnectivityMap = networkNodeMetrics?.nodes[nodeId].connectivity;
      const res = {
        nodeId: nodeId,
        nodeName: networkNodeMetrics?.nodes[nodeId].node_name ?? '',
        latency: {} as NodeMetricsTableData['latency'],
      };
      Object.keys(nodeConnectivityMap ?? {}).reduce((acc, key) => {
        acc.latency![key] = nodeConnectivityMap?.[key].latency ?? 0;
        return acc;
      }, res);
      return res;
    });
  }, [networkNodeMetrics?.nodes]);

  const bytesSentMetricsData = useMemo<NodeMetricsTableData[]>(() => {
    return Object.keys(networkNodeMetrics?.nodes ?? {}).map((nodeId) => {
      const nodeConnectivityMap = networkNodeMetrics?.nodes[nodeId].connectivity;
      const res = {
        nodeId: nodeId,
        nodeName: networkNodeMetrics?.nodes[nodeId].node_name ?? '',
        bytesSent: {} as NodeMetricsTableData['bytesSent'],
      };
      Object.keys(nodeConnectivityMap ?? {}).reduce((acc, key) => {
        acc.bytesSent![key] = nodeConnectivityMap?.[key].totalsent ?? 0;
        return acc;
      }, res);
      return res;
    });
  }, [networkNodeMetrics?.nodes]);

  const bytesReceivedMetricsData = useMemo<NodeMetricsTableData[]>(() => {
    return Object.keys(networkNodeMetrics?.nodes ?? {}).map((nodeId) => {
      const nodeConnectivityMap = networkNodeMetrics?.nodes[nodeId].connectivity;
      const res = {
        nodeId: nodeId,
        nodeName: networkNodeMetrics?.nodes[nodeId].node_name ?? '',
        bytesReceived: {} as NodeMetricsTableData['bytesReceived'],
      };
      Object.keys(nodeConnectivityMap ?? {}).reduce((acc, key) => {
        acc.bytesReceived![key] = nodeConnectivityMap?.[key].totalreceived ?? 0;
        return acc;
      }, res);
      return res;
    });
  }, [networkNodeMetrics?.nodes]);

  const uptimeMetricsData = useMemo<NodeMetricsTableData[]>(() => {
    return Object.keys(networkNodeMetrics?.nodes ?? {}).map((nodeId) => {
      const nodeConnectivityMap = networkNodeMetrics?.nodes[nodeId].connectivity;
      const res = {
        nodeId: nodeId,
        nodeName: networkNodeMetrics?.nodes[nodeId].node_name ?? '',
        uptime: {} as NodeMetricsTableData['uptime'],
      };
      Object.keys(nodeConnectivityMap ?? {}).reduce((acc, key) => {
        acc.uptime![key] = {
          fractionalUptime: nodeConnectivityMap?.[key].uptime ?? 0,
          totalFractionalUptime: nodeConnectivityMap?.[key].totaltime ?? 0,
          uptime: nodeConnectivityMap?.[key].actualuptime ?? 0,
          uptimePercent: nodeConnectivityMap?.[key].percentup.toFixed(2) ?? 0,
        };
        return acc;
      }, res);
      return res;
    });
  }, [networkNodeMetrics?.nodes]);

  const clientsMetricsData = useMemo<NodeOrClientMetric[]>(() => {
    return Object.values(clientMetrics ?? {});
  }, [clientMetrics]);

  const loadAcls = useCallback(async () => {
    try {
      if (!networkId) return;
      const acls = (await NetworksService.getAcls(networkId)).data;
      setNodeAcls(acls);
      setOriginalNodeAcls(acls);
    } catch (err) {
      if (err instanceof AxiosError) {
        notify.error({
          message: t('zncGnIv873If695VTnK6'),
          description: extractErrorMsg(err),
        });
      }
    }
  }, [networkId, notify]);

  const editNode = useCallback((node: Node) => {
    setTargetNode(node);
    setIsUpdateNodeModalOpen(true);
  }, []);

  // const downloadMetrics = useCallback(() => {}, []);

  const loadClients = useCallback(async () => {
    try {
      if (!networkId) return;
      const networkClients = (await NodesService.getNetworkExternalClients(networkId)).data ?? [];
      setClients(networkClients);
      const clientAclsContainer = {} as Record<ExternalClient['clientid'], ExtClientAcls>;
      networkClients.forEach((client) => {
        clientAclsContainer[client.clientid] = client.deniednodeacls ?? {};
      });
      setOriginalClientAcls(clientAclsContainer);
      setClientAcls(clientAclsContainer);
    } catch (err) {
      notify.error({
        message: t('pz8SsX3B2yuyJ0xIys4sk'),
        description: extractErrorMsg(err as any),
      });
    }
  }, [networkId, notify]);

  const confirmDeleteClient = useCallback(
    (client: ExternalClient) => {
      Modal.confirm({
        title: t('l4lJJeks_9Eg6C9ReXyIt', { client: client.clientid }),
        content: t('ov3iJfhDoW8gyWinMg0Zz'),
        onOk: async () => {
          try {
            await NodesService.deleteExternalClient(client.clientid, client.network);
            setClients((prev) => prev.filter((c) => c.clientid !== client.clientid));
            storeFetchNodes();
          } catch (err) {
            notify.error({
              message: t('bdhacNlqTzkzwHm6Cvls8'),
              description: extractErrorMsg(err as any),
            });
          }
        },
      });
    },
    [notify, storeFetchNodes],
  );

  const openClientDetails = useCallback((client: ExternalClient) => {
    setTargetClient(client);
    setIsClientDetailsModalOpen(true);
  }, []);

  const confirmDeleteGateway = useCallback(
    (gateway: Node) => {
      Modal.confirm({
        title: t('np1OtL9Fg6tt5U3qJlz1', { name: getExtendedNode(gateway, store.hostsCommonDetails).name }),
        content: t('3EyZ3ua9rb4WyykxBnuip'),
        onOk: async () => {
          try {
            await NodesService.deleteIngressNode(gateway.id, gateway.network);
            storeFetchNodes();
            loadClients();
          } catch (err) {
            if (err instanceof AxiosError) {
              notify.error({
                message: t('xB8Cxnjy0bXGy18kiiz32'),
                description: extractErrorMsg(err),
              });
            }
          }
        },
      });
    },
    [loadClients, notify, store.hostsCommonDetails, storeFetchNodes],
  );

  const confirmDeleteEgress = useCallback(
    (egress: Node) => {
      Modal.confirm({
        title: t('pmFrKFptYjFcXg3jgrgaC', { name: getExtendedNode(egress, store.hostsCommonDetails).name }),
        content: t('p9k2G0JUboRvuNK7gmFc'),
        onOk: async () => {
          try {
            await NodesService.deleteEgressNode(egress.id, egress.network);
            storeFetchNodes();
          } catch (err) {
            if (err instanceof AxiosError) {
              notify.error({
                message: t('2UxGmCmZSJhMnj5coi5'),
                description: extractErrorMsg(err),
              });
            }
          }
        },
      });
    },
    [notify, store.hostsCommonDetails, storeFetchNodes],
  );

  const confirmDeleteRange = useCallback(
    (range: ExternalRoutesTableData) => {
      Modal.confirm({
        title: t('w4C4eWlUHcnWq9uOBhsQs', { range: range.range, name: range.node?.name ?? '' }),
        content: t('58WljDfmWfxh4ces5qrUm'),
        onOk: async () => {
          try {
            if (!networkId) return;
            const newRanges = new Set(range.node.egressgatewayranges);
            const natEnabled = range.node.egressgatewaynatenabled;
            newRanges.delete(range.range);
            await NodesService.deleteEgressNode(range.node.id, networkId);
            if (newRanges.size > 0) {
              await NodesService.createEgressNode(range.node.id, networkId, {
                ranges: [...newRanges],
                natEnabled: natEnabled ? t('xdLOgKbztv5Xu9uxuz0A') : t('mjW04pKxnQmmURzkHCg'),
              });
            }
            store.fetchNodes();
          } catch (err) {
            if (err instanceof AxiosError) {
              notify.error({
                message: t('bpePxgUwuxbCbEd5xEt33'),
                description: extractErrorMsg(err),
              });
            }
          }
        },
      });
    },
    [networkId, notify, store],
  );

  const confirmDeleteDns = useCallback(
    (dns: DNS) => {
      Modal.confirm({
        title: t('tso8iD5s7ZvFgr15TxLN', { name: dns.name, network: dns.network }),
        content: t('cuNjLEwaPk7fEgJppVbBr'),
        onOk: async () => {
          try {
            await NetworksService.deleteDns(dns.network, dns.name);
            setDnses((dnses) => dnses.filter((d) => d.name !== dns.name));
            notify.success({ message: t('tk8sdXglyXxfOjLc8GzS') });
          } catch (err) {
            if (err instanceof AxiosError) {
              notify.error({
                message: t('aCdgYyN0W0PjQ5MvPsHpy'),
                description: extractErrorMsg(err),
              });
            }
          }
        },
      });
    },
    [notify],
  );

  const confirmDeleteRelay = useCallback(
    (relay: ExtendedNode) => {
      if (!networkId) return;

      Modal.confirm({
        title: t('up7pvyzej6LEnqij9HsNf', { name: relay.name }),
        content: t('lgvvnWi6urL1sl2yg5hV'),
        onOk: async () => {
          try {
            await NodesService.deleteRelay(relay.id, networkId);
            store.fetchNodes();
            notify.success({ message: t('1IpOi6ESS4xrDbzjMi') });
          } catch (err) {
            notify.error({
              message: t('kiKaxa0RXohwHiiiuyLta'),
              description: extractErrorMsg(err as any),
            });
          }
        },
      });
    },
    [notify, store, networkId],
  );

  const confirmRemoveRelayed = useCallback(
    (relayed: ExtendedNode, relay: ExtendedNode) => {
      if (!networkId) return;

      Modal.confirm({
        title: t('wIxtyMnm8kX3a2Hv2xPb', { relayed: relayed.name, relay: relay.name }),
        content: t('7jQmsnEmcEh6pUu2PVis'),
        onOk: async () => {
          try {
            const relayedIds = new Set([...(relay.relaynodes ?? [])]);
            relayedIds.delete(relayed.id);

            if (relayedIds.size > 0) {
              await NodesService.updateNode(relay.id, networkId, { ...relay, relaynodes: [...relayedIds] });
            } else {
              (await NodesService.deleteRelay(relay.id, networkId)).data;
            }

            storeFetchNodes();
          } catch (err) {
            notify.error({
              message: t('oqqX9BhbvjRmLxOGtY2x'),
              description: extractErrorMsg(err as any),
            });
          }
        },
      });
    },
    [networkId, notify, storeFetchNodes],
  );

  const gatewaysTableCols = useMemo<TableColumnProps<ExtendedNode>[]>(
    () => [
      {
        title: t('ne04tjkCuljMIR0T9pM'),
        dataIndex: 'name',
        // width: 500,
        render(name) {
          return <Typography.Link>{name}</Typography.Link>;
        },
        sorter: (a, b) => a.name?.localeCompare(b.name ?? '') ?? 0,
        defaultSortOrder: 'ascend',
      },
      {
        title: t('uffwzLygjAbk41IuDpBA'),
        dataIndex: 'address',
        render(_, node) {
          const addrs = `${node.address}, ${node.address6}`;
          return <Tooltip title={addrs}>{addrs}</Tooltip>;
        },
      },
      {
        title: t('31T4VGqDDgp5Vy56Sf3mI'),
        dataIndex: 'endpointip',
      },
      {
        title: t('qxNxX5i3zEf6S2mBNbn'),
        dataIndex: 'ingressdns',
      },
      {
        render(_, gateway) {
          return (
            <Dropdown
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: 'edit',
                    label: (
                      <Typography.Text
                        onClick={() => {
                          setSelectedGateway(gateway);
                          setIsUpdateGatewayModalOpen(true);
                        }}
                      >
                        <EditOutlined /> {t('giSlJxeyxvkwuaiGyFDji')}
                      </Typography.Text>
                    ),
                    onClick: (info) => {
                      info.domEvent.stopPropagation();
                    },
                  },
                  {
                    key: 'delete',
                    label: (
                      <Typography.Text onClick={() => confirmDeleteGateway(gateway)}>
                        <DeleteOutlined /> {t('u7MiYLt4iCgCJmjgCy8x2')}
                      </Typography.Text>
                    ),
                    onClick: (info) => {
                      info.domEvent.stopPropagation();
                    },
                  },
                ] as MenuProps['items'],
              }}
            >
              <Button type="text" icon={<MoreOutlined />} />
            </Dropdown>
          );
        },
      },
    ],
    [confirmDeleteGateway],
  );

  const egressTableCols = useMemo<TableColumnProps<ExtendedNode>[]>(
    () => [
      {
        title: t('ne04tjkCuljMIR0T9pM'),
        dataIndex: 'name',
        width: 500,
        render(name) {
          return <Typography.Link>{name}</Typography.Link>;
        },
        sorter: (a, b) => a.name?.localeCompare(b.name ?? '') ?? 0,
        defaultSortOrder: 'ascend',
      },
      {
        title: t('uffwzLygjAbk41IuDpBA'),
        dataIndex: 'address',
        render(_, node) {
          const addrs = `${node.address}, ${node.address6}`;
          return <Tooltip title={addrs}>{addrs}</Tooltip>;
        },
      },
      {
        title: t('31T4VGqDDgp5Vy56Sf3mI'),
        dataIndex: 'endpointip',
      },
      {
        width: '1rem',
        render(_, egress) {
          return (
            <Dropdown
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: 'update',
                    label: (
                      <Typography.Text
                        onClick={() => {
                          setFilteredEgress(egress);
                          setIsUpdateEgressModalOpen(true);
                        }}
                      >
                        <EditOutlined /> {t('sLvFi4ngvxRhFcZ6x22qU')}
                      </Typography.Text>
                    ),
                    onClick: (info) => {
                      info.domEvent.stopPropagation();
                    },
                  },
                  {
                    key: 'delete',
                    label: (
                      <Typography.Text onClick={() => confirmDeleteEgress(egress)}>
                        <DeleteOutlined /> {t('u7MiYLt4iCgCJmjgCy8x2')}
                      </Typography.Text>
                    ),
                    onClick: (info) => {
                      info.domEvent.stopPropagation();
                    },
                  },
                ] as MenuProps['items'],
              }}
            >
              <Button type="text" icon={<MoreOutlined />} />
            </Dropdown>
          );
        },
      },
    ],
    [confirmDeleteEgress],
  );

  const externalRoutesTableCols = useMemo<TableColumnProps<ExternalRoutesTableData>[]>(() => {
    return [
      {
        title: t('djuUp7mIC7EdMoyOoe3j'),
        dataIndex: 'range',
      },
      {
        title: t('eaXgPazOZlcNksWtFpVh'),
        render(_, range) {
          return range.node?.name ?? '';
        },
      },
      {
        width: '1rem',
        render(_, range) {
          return (
            <Dropdown
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: 'delete',
                    label: (
                      <Typography.Text onClick={() => confirmDeleteRange(range)}>
                        <DeleteOutlined /> {t('u7MiYLt4iCgCJmjgCy8x2')}
                      </Typography.Text>
                    ),
                  },
                ] as MenuProps['items'],
              }}
            >
              <Button type="text" icon={<MoreOutlined />} />
            </Dropdown>
          );
        },
      },
    ];
  }, [confirmDeleteRange]);

  const clientsTableCols = useMemo<TableColumnProps<ExternalClient>[]>(
    () => [
      {
        title: t('7lN0jUg8u4JcaV5jsRvM'),
        dataIndex: 'clientid',
        width: 500,
        render(value, client) {
          return <Typography.Link onClick={() => openClientDetails(client)}>{value}</Typography.Link>;
        },
      },
      {
        title: t('4fNXvurl2bX9LfJMmXhp'),
        render(_, client) {
          const addrs = `${client.address}, ${client.address6}`;
          return <Tooltip title={addrs}>{addrs}</Tooltip>;
        },
      },
      // {
      //   title: 'Public Key',
      //   dataIndex: 'publickey',
      //   width: 200,
      //   render(value) {
      //     return (
      //       <div style={{ width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      //         {value}
      //       </div>
      //     );
      //   },
      // },
      {
        title: t('wzr0ysDByMr0a2MQvNhw'),
        width: 200,
        render(_, client) {
          const assocIngress = networkNodes.find((node) => node.id === client.ingressgatewayid);
          return assocIngress ? getExtendedNode(assocIngress, store.hostsCommonDetails).name ?? '' : '';
        },
      },
      {
        title: t('8QlPueX9bvPfv59vFh0X'),
        dataIndex: 'enabled',
        render(value, client) {
          return (
            <Switch
              checked={value}
              onChange={(checked) => {
                toggleClientStatus(client, checked);
              }}
            />
          );
        },
      },
      {
        render(_, client) {
          return (
            <Dropdown
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: 'edit',
                    label: (
                      <Typography.Text
                        onClick={() => {
                          setTargetClient(client);
                          setIsUpdateClientModalOpen(true);
                        }}
                      >
                        <EditOutlined /> {t('giSlJxeyxvkwuaiGyFDji')}
                      </Typography.Text>
                    ),
                  },
                  {
                    key: 'delete',
                    label: (
                      <Typography.Text onClick={() => confirmDeleteClient(client)}>
                        <DeleteOutlined /> {t('u7MiYLt4iCgCJmjgCy8x2')}
                      </Typography.Text>
                    ),
                  },
                ] as MenuProps['items'],
              }}
            >
              <Button type="text" icon={<MoreOutlined />} />
            </Dropdown>
          );
        },
      },
    ],
    [confirmDeleteClient, networkNodes, openClientDetails, store.hostsCommonDetails, toggleClientStatus],
  );

  const relayTableCols = useMemo<TableColumnProps<ExtendedNode>[]>(
    () => [
      {
        title: t('ne04tjkCuljMIR0T9pM'),
        dataIndex: 'name',
        sorter: (a, b) => a.name?.localeCompare(b.name ?? '') ?? 0,
        defaultSortOrder: 'ascend',
      },
      {
        title: t('uffwzLygjAbk41IuDpBA'),
        dataIndex: 'address',
        render(_, node) {
          const addrs = `${node.address ?? ''}, ${node.address6 ?? ''}`;
          return <Tooltip title={addrs}>{addrs}</Tooltip>;
        },
      },
      {
        width: '1rem',
        render(_, relay) {
          return (
            <Dropdown
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: 'update',
                    label: (
                      <Typography.Text
                        onClick={() => {
                          setSelectedRelay(relay);
                          setIsUpdateRelayModalOpen(true);
                        }}
                      >
                        <EditOutlined /> {t('sLvFi4ngvxRhFcZ6x22qU')}
                      </Typography.Text>
                    ),
                    onClick: (info) => {
                      info.domEvent.stopPropagation();
                    },
                  },
                  {
                    key: 'delete',
                    label: (
                      <Typography.Text onClick={() => confirmDeleteRelay(relay)}>
                        <DeleteOutlined /> {t('u7MiYLt4iCgCJmjgCy8x2')}
                      </Typography.Text>
                    ),
                    onClick: (info) => {
                      info.domEvent.stopPropagation();
                    },
                  },
                ] as MenuProps['items'],
              }}
            >
              <Button type="text" icon={<MoreOutlined />} />
            </Dropdown>
          );
        },
      },
    ],
    [confirmDeleteRelay],
  );

  const relayedTableCols = useMemo<TableColumnProps<ExtendedNode>[]>(
    () => [
      {
        title: t('ne04tjkCuljMIR0T9pM'),
        dataIndex: 'name',
      },
      {
        title: t('qr0Nf4z5Ye6pkuiwpPCr1'),
        render(_, node) {
          return `${networkNodes.find((n) => n.id === node.relayedby)?.name ?? ''}`;
        },
      },
      {
        title: t('uffwzLygjAbk41IuDpBA'),
        dataIndex: 'address',
        render(_, node) {
          const addrs = `${node.address ?? ''}, ${node.address6 ?? ''}`;
          return <Tooltip title={addrs}>{addrs}</Tooltip>;
        },
      },
      {
        width: '1rem',
        render(_, relayed) {
          return (
            <Dropdown
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: 'delete',
                    label: (
                      <Typography.Text
                        onClick={() =>
                          confirmRemoveRelayed(
                            relayed,
                            networkNodes.find((node) => node.id === relayed.relayedby) ?? NULL_NODE,
                          )
                        }
                      >
                        <DeleteOutlined /> {t('llfEpRx96aFCbfhljkSz')}
                      </Typography.Text>
                    ),
                    onClick: (info) => {
                      info.domEvent.stopPropagation();
                    },
                  },
                ] as MenuProps['items'],
              }}
            >
              <Button type="text" icon={<MoreOutlined />} />
            </Dropdown>
          );
        },
      },
    ],
    [confirmRemoveRelayed, networkNodes],
  );

  const aclTableCols = useMemo<TableColumnProps<AclTableData>[]>(() => {
    const aclTableDataMap = new Map<Node['id'] | ExternalClient['clientid'], AclTableData>();
    aclTableData.forEach((aclData) => aclTableDataMap.set(aclData.nodeOrClientId, aclData));

    const renderAclValue = (
      rowColTypeTuple: [rowType: 'node' | 'client', colType: 'node' | 'client'],
      originalAclLevel: AclStatus,
      newAclLevel: AclStatus,
      nodeOrClientIdRow: Node['id'] | ExternalClient['clientid'],
      nodeOrClientIdCol: Node['id'] | ExternalClient['clientid'],
    ) => {
      const type = rowColTypeTuple.some((t) => t === 'client') ? 'client' : 'node';
      if (type === 'node') {
        switch (newAclLevel) {
          case ACL_DENIED:
            return (
              <Badge size="small" dot={originalAclLevel !== newAclLevel}>
                <Button
                  danger
                  size="small"
                  icon={<StopOutlined />}
                  onClick={() => {
                    setNodeAcls((prevAcls) => {
                      const newAcls = structuredClone(prevAcls);
                      newAcls[nodeOrClientIdRow][nodeOrClientIdCol] = 2;
                      newAcls[nodeOrClientIdCol][nodeOrClientIdRow] = 2;
                      return newAcls;
                    });
                  }}
                />
              </Badge>
            );
          case ACL_ALLOWED:
            return (
              <Badge size="small" dot={originalAclLevel !== newAclLevel}>
                <Button
                  size="small"
                  style={{ color: '#3C8618', borderColor: '#274916' }}
                  icon={<CheckOutlined />}
                  onClick={() => {
                    setNodeAcls((prevAcls) => {
                      const newAcls = structuredClone(prevAcls);
                      newAcls[nodeOrClientIdRow][nodeOrClientIdCol] = 1;
                      newAcls[nodeOrClientIdCol][nodeOrClientIdRow] = 1;
                      return newAcls;
                    });
                  }}
                />
              </Badge>
            );
          default:
            return <DashOutlined />;
        }
      } else {
        if (rowColTypeTuple[1] === 'node') {
          return <DashOutlined />;
        }
        // TODO: optimise this bit of logic to prevent O^2. maybe refactor
        const assocClient = clients.find((c) => c.clientid === nodeOrClientIdCol);
        const assocIngress = networkNodes.find((n) => n.id === assocClient?.ingressgatewayid);
        const assocIngressDenyList = Object.keys(nodeAcls[assocIngress?.id ?? ''] ?? {}).filter(
          (targetNodeId) => nodeAcls[assocIngress?.id ?? ''][targetNodeId] === ACL_DENIED,
        );
        if (assocIngressDenyList.includes(nodeOrClientIdRow)) {
          return (
            <Badge size="small" dot={originalAclLevel !== newAclLevel}>
              <Button
                danger
                disabled
                // title={`The associated ingress gateway (${
                //   assocIngress?.name ?? ''
                // }) has denied this client access to this node.`}
                title={t('pmju5ECmOilfqlBiH8ZJs', { gateway: assocIngress?.name ?? '' })}
                size="small"
                icon={<StopOutlined />}
              />
            </Badge>
          );
        }
        switch (newAclLevel) {
          case ACL_DENIED:
            return (
              <Badge size="small" dot={originalAclLevel !== newAclLevel}>
                <Button
                  danger
                  size="small"
                  icon={<StopOutlined />}
                  onClick={() => {
                    setClientAcls((prevClientAcls) => {
                      const newClientAcls = structuredClone(prevClientAcls);
                      // this check is because client acl data structure is not interchangeable an in that of nodes
                      // this manipulation leads to "dirty data."
                      // ie: clientAcls gets populated with node IDs as if they are clients
                      // can be improved but doesnt matter at the moment as it would be ignored when sending to the server
                      if (newClientAcls[nodeOrClientIdRow]) {
                        delete newClientAcls[nodeOrClientIdRow][nodeOrClientIdCol];
                        if (newClientAcls[nodeOrClientIdCol]) {
                          delete newClientAcls[nodeOrClientIdRow][nodeOrClientIdCol];
                        }
                      }
                      if (newClientAcls[nodeOrClientIdCol]) {
                        delete newClientAcls[nodeOrClientIdCol][nodeOrClientIdRow];
                        if (newClientAcls[nodeOrClientIdRow]) {
                          delete newClientAcls[nodeOrClientIdCol][nodeOrClientIdRow];
                        }
                      }
                      return newClientAcls;
                    });
                  }}
                />
              </Badge>
            );
          case ACL_ALLOWED:
            return (
              <Badge size="small" dot={originalAclLevel !== newAclLevel}>
                <Button
                  size="small"
                  style={{ color: '#3C8618', borderColor: '#274916' }}
                  icon={<CheckOutlined />}
                  onClick={() => {
                    setClientAcls((prevClientAcls) => {
                      const newClientAcls = structuredClone(prevClientAcls);
                      // this check is because client acl data structure is not interchangeable an in that of nodes
                      // this manipulation leads to "dirty data."
                      // ie: clientAcls gets populated with node IDs as if they are clients
                      // can be improved but doesnt matter at the moment as it would be ignored when sending to the server
                      if (newClientAcls[nodeOrClientIdRow]) {
                        newClientAcls[nodeOrClientIdRow][nodeOrClientIdCol] = {} as never;
                        newClientAcls[nodeOrClientIdCol] = {
                          ...(newClientAcls[nodeOrClientIdCol] ?? {}),
                          [nodeOrClientIdRow]: {} as never,
                        };
                      }
                      if (newClientAcls[nodeOrClientIdCol]) {
                        newClientAcls[nodeOrClientIdCol][nodeOrClientIdRow] = {} as never;
                        newClientAcls[nodeOrClientIdRow] = {
                          ...(newClientAcls[nodeOrClientIdRow] ?? {}),
                          [nodeOrClientIdCol]: {} as never,
                        };
                      }
                      return newClientAcls;
                    });
                  }}
                />
              </Badge>
            );
          default:
            return <DashOutlined />;
        }
      }
    };

    return [
      {
        width: '5rem',
        fixed: 'left',
        render(_, entry) {
          return (
            <Typography.Text
              style={{
                width: '5rem',
                wordBreak: 'keep-all',
              }}
              onClick={() => setSearchAclHost(entry.name)}
            >
              {entry.name}
            </Typography.Text>
          );
        },
      },
      ...aclTableData.map((aclData) => ({
        title: aclData.name,
        width: '5rem',
        render(_: unknown, aclEntry: (typeof aclTableData)[0]) {
          // aclData => column, aclEntry => row
          const aclType = [aclData.type, aclEntry.type].some((t) => t === 'client') ? 'client' : 'node';

          return renderAclValue(
            // row/col type tuple
            [aclEntry.type, aclData.type],
            // original acl status
            aclType === 'node'
              ? originalNodeAcls?.[aclEntry.nodeOrClientId]?.[aclData.nodeOrClientId] ?? 0
              : getExtClientAclStatus(aclEntry.nodeOrClientId, originalClientAcls[aclData.nodeOrClientId] ?? {}),
            // new acl status
            aclType === 'node'
              ? aclTableDataMap.get(aclEntry.nodeOrClientId)?.acls?.[aclData?.nodeOrClientId] ?? 0
              : aclEntry.nodeOrClientId === aclData.nodeOrClientId // check disable toggling ones own self
              ? 0
              : getExtClientAclStatus(
                  aclEntry.nodeOrClientId,
                  aclTableDataMap.get(aclData.nodeOrClientId)?.clientAcls ?? {},
                ),
            // node or client IDs
            aclEntry.nodeOrClientId,
            aclData.nodeOrClientId,
          );
        },
      })),
    ];
  }, [aclTableData, clients, networkNodes, nodeAcls, originalClientAcls, originalNodeAcls]);

  const hasAclsBeenEdited = useMemo(
    () =>
      JSON.stringify(nodeAcls) !== JSON.stringify(originalNodeAcls) ||
      JSON.stringify(clientAcls) !== JSON.stringify(originalClientAcls),
    [clientAcls, nodeAcls, originalClientAcls, originalNodeAcls],
  );

  const metricsTableCols = useMemo<TableColumnProps<NodeMetricsTableData>[]>(() => {
    switch (currentMetric) {
      case 'connectivity-status':
        return [
          {
            title: '',
            width: '10rem',
            render(_, entry) {
              return (
                <Typography.Text onClick={() => setFilteredMetricNodeId(entry.nodeId)}>
                  {entry.nodeName}
                </Typography.Text>
              );
            },
          },
          ...connectivityStatusMetricsData.map((metricData) => ({
            title: metricData.nodeName,
            render(_: unknown, metricEntry: (typeof connectivityStatusMetricsData)[0]) {
              if (metricEntry.nodeId === metricData.nodeId) {
                return <DashOutlined />;
              }
              return renderMetricValue(currentMetric, metricData?.connectivity?.[metricEntry?.nodeId] ?? false);
            },
          })),
        ];
        break;
      case 'latency':
        return [
          {
            title: '',
            width: '10rem',
            render(_, entry) {
              return (
                <Typography.Text onClick={() => setFilteredMetricNodeId(entry.nodeId)}>
                  {entry.nodeName}
                </Typography.Text>
              );
            },
          },
          ...latencyMetricsData.map((metricData) => ({
            title: metricData.nodeName,
            render(_: unknown, metricEntry: (typeof latencyMetricsData)[0]) {
              if (metricEntry.nodeId === metricData.nodeId) {
                return <DashOutlined />;
              }
              return renderMetricValue(currentMetric, metricData?.latency?.[metricEntry?.nodeId] ?? 0);
            },
          })),
        ];
        break;
      case 'bytes-sent':
        return [
          {
            title: '',
            width: '10rem',
            render(_, entry) {
              return (
                <Typography.Text onClick={() => setFilteredMetricNodeId(entry.nodeId)}>
                  {entry.nodeName}
                </Typography.Text>
              );
            },
          },
          ...bytesSentMetricsData.map((metricData) => ({
            title: metricData.nodeName,
            render(_: unknown, metricEntry: (typeof bytesSentMetricsData)[0]) {
              if (metricEntry.nodeId === metricData.nodeId) {
                return <DashOutlined />;
              }
              return renderMetricValue(currentMetric, metricData?.bytesSent?.[metricEntry?.nodeId] ?? 0);
            },
          })),
        ];
        break;
      case 'bytes-received':
        return [
          {
            title: '',
            width: '10rem',
            render(_, entry) {
              return (
                <Typography.Text onClick={() => setFilteredMetricNodeId(entry.nodeId)}>
                  {entry.nodeName}
                </Typography.Text>
              );
            },
          },
          ...bytesReceivedMetricsData.map((metricData) => ({
            title: metricData.nodeName,
            render(_: unknown, metricEntry: (typeof bytesReceivedMetricsData)[0]) {
              if (metricEntry.nodeId === metricData.nodeId) {
                return <DashOutlined />;
              }
              return renderMetricValue(currentMetric, metricData?.bytesReceived?.[metricEntry?.nodeId] ?? 0);
            },
          })),
        ];
        break;
      case 'uptime':
        return [
          {
            title: '',
            width: '10rem',
            render(_, entry) {
              return (
                <Typography.Text onClick={() => setFilteredMetricNodeId(entry.nodeId)}>
                  {entry.nodeName}
                </Typography.Text>
              );
            },
          },
          ...uptimeMetricsData.map((metricData) => ({
            title: metricData.nodeName,
            render(_: unknown, metricEntry: (typeof uptimeMetricsData)[0]) {
              if (metricEntry.nodeId === metricData.nodeId) {
                return <DashOutlined />;
              }
              return renderMetricValue(currentMetric, metricData?.uptime?.[metricEntry?.nodeId] ?? {});
            },
          })),
        ];
        break;
      default:
        return [];
    }
  }, [
    bytesReceivedMetricsData,
    bytesSentMetricsData,
    connectivityStatusMetricsData,
    currentMetric,
    latencyMetricsData,
    uptimeMetricsData,
  ]);

  const clientMetricsTableCols = useMemo<TableColumnProps<NodeOrClientMetric>[]>(() => {
    return [
      {
        title: t('0oz7GjAoBvdibi2St9MhH'),
        dataIndex: 'node_name',
      },
      {
        title: t('aBbehXc5a4YyYnu7zwXu'),
        dataIndex: 'connected',
        render: (val) => renderMetricValue('connectivity-status', val),
      },
      {
        title: t('cwqPu_3Tb5wRgmh1mCg5J'),
        dataIndex: 'uptime',
        render: (val, data) => {
          const uptime: UptimeNodeMetrics = {
            uptime: data.actualuptime ?? 0,
            fractionalUptime: data.uptime ?? 0,
            totalFractionalUptime: data.totaltime ?? 0,
            uptimePercent: data?.percentup?.toFixed(2) ?? 0,
          };
          return renderMetricValue('uptime', uptime);
        },
      },
      {
        title: t('gD75aW9Te9enTXpPxPkvx'),
        render(_, data) {
          return renderMetricValue('latency', data.latency);
        },
      },
      {
        title: t('dNZeF8lQXedozJrPgNgHv'),
        render(_, data) {
          return renderMetricValue('bytes-sent', data.totalsent);
        },
      },
      {
        title: t('rHVeJzuxsC2JzqRx9Lsp'),
        render(_, data) {
          return renderMetricValue('bytes-received', data.totalreceived);
        },
      },
    ];
  }, []);

  const isDefaultDns = useCallback(
    (dns: DNS) => {
      return networkNodes.some((node) => getExtendedNode(node, store.hostsCommonDetails).name === dns.name);
    },
    [networkNodes, store.hostsCommonDetails],
  );

  const removeNodeFromNetwork = useCallback(
    (newStatus: boolean, node: ExtendedNode) => {
      let forceDelete = false;

      Modal.confirm({
        title: t('wUvbWqZ7ULjQbcUhWVdm'),
        content: (
          <>
            <Row>
              <Col xs={24}>
                <Typography.Text>
                  {t('z3t5Godm39LcYz2INrgNo', {
                    name: node?.name ?? '',
                    status: newStatus ? t('nfiUsHvh3DvD0tFaY93t') : t('ibUuPAe5uaiO16YtsFlJ'),
                  })}
                </Typography.Text>
              </Col>
              <Col xs={24}>
                <Form.Item
                  htmlFor="force-delete"
                  label="Force delete"
                  valuePropName="checked"
                  style={{ marginBottom: '0px' }}
                >
                  <Checkbox
                    id="force-delete"
                    onChange={(e) => {
                      forceDelete = e.target.checked;
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        ),
        async onOk() {
          try {
            if (!networkId) return;
            await HostsService.updateHostsNetworks(node.hostid, networkId, newStatus ? 'join' : 'leave', forceDelete);
            if (forceDelete) {
              storeDeleteNode(node.id);
            }
            notify.success({
              message: t('jtbVhrqkezWwl3Fpg9Ost', {
                status: newStatus ? t('kvNoDLic_7HsMhUYcOzk') : t('lN4vjPi4q_531Eigvixwb'),
              }),
              description: t('m8PYDWbl6xRnw402nOe7', {
                nodeName: node?.name ?? t('eaXgPazOZlcNksWtFpVh'),
                status: newStatus ? t('adyjtThxktgct62AdWsIy') : t('tseUaUrCd9Uy7P8kov7Ki'),
                network: networkId,
              }),
            });
          } catch (err) {
            notify.error({
              message: t('kSuTupoZxE9CFmIbxkRd'),
              description: extractErrorMsg(err as any),
            });
          }
        },
      });
    },
    [networkId, notify, storeDeleteNode],
  );

  const disconnectNodeFromNetwork = useCallback(
    (newStatus: boolean, node: ExtendedNode) => {
      Modal.confirm({
        title: newStatus ? t('qjtTJjGIubGuTQqfRui1') : t('ijvsqJbkphLnxdX39lE9'),
        content: (
          <>
            <Row>
              <Col xs={24}>
                <Typography.Text>
                  {t('z3t5Godm39LcYz2INrgNo', {
                    name: node?.name ?? '',
                    status: newStatus ? t('tgVtWu0CyeuGC5WsQxB') : t('1qFiYdtfPajuc_5kKeBIl'),
                  })}
                </Typography.Text>
              </Col>
            </Row>
          </>
        ),
        async onOk() {
          try {
            if (!networkId) return;
            const updatedNode = (await NodesService.updateNode(node.id, networkId, { ...node, connected: newStatus }))
              .data;
            store.updateNode(node.id, updatedNode);
            notify.success({
              message: t('jtbVhrqkezWwl3Fpg9Ost', {
                status: newStatus ? t('kvNoDLic_7HsMhUYcOzk') : t('lN4vjPi4q_531Eigvixwb'),
              }),
              description: t('qW7j3QoOrs_2SzYtLIsec', {
                nodeName: node?.name ?? 'Host',
                status: newStatus ? t('adyjtThxktgct62AdWsIy') : t('tseUaUrCd9Uy7P8kov7Ki'),
                network: networkId,
              }),
            });
          } catch (err) {
            notify.error({
              message: t('kSuTupoZxE9CFmIbxkRd'),
              description: extractErrorMsg(err as any),
            });
          }
        },
      });
    },
    [networkId, notify, store],
  );

  const updateAllClientsAcls = useCallback(async () => {
    // TODO: optimise function or entire client ACL update flow. this wont scale
    if (!networkId || !isServerEE) return;
    for (let i = 0; i < clients.length; i++) {
      const c = clients[i];
      await NodesService.updateExternalClient(c.clientid, networkId, {
        ...c,
        deniednodeacls: clientAcls[c.clientid] ?? {},
      });
    }
    loadClients();
  }, [clientAcls, clients, isServerEE, loadClients, networkId]);

  // ui components
  const getOverviewContent = useCallback(() => {
    if (!network) return <Skeleton active />;
    return (
      <div className="" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Card style={{ width: '50%' }}>
          <Form
            name="network-details-form"
            form={form}
            layout="vertical"
            initialValues={network}
            disabled={!isEditingNetwork}
          >
            <Form.Item
              label={t('boyEt2hI06JhCpK8irwpD')}
              name="netid"
              rules={[{ required: true }]}
              data-nmui-intercom="network-details-form_netid"
            >
              <Input placeholder={t('boyEt2hI06JhCpK8irwpD')} disabled />
            </Form.Item>

            {/* ipv4 */}
            <Row
              style={{
                border: `1px solid ${themeToken.colorBorder}`,
                borderRadius: '8px',
                padding: '.5rem',
                marginBottom: '1.5rem',
              }}
            >
              <Col xs={24}>
                <Row justify="space-between" style={{ marginBottom: isIpv4Watch ? '.5rem' : '0px' }}>
                  <Col>IPv4</Col>
                  <Col>
                    <Form.Item
                      name="isipv4"
                      valuePropName="checked"
                      style={{ marginBottom: '0px' }}
                      data-nmui-intercom="network-details-form_isipv4"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                {isIpv4Watch && (
                  <Row>
                    <Col xs={24}>
                      <Form.Item
                        name="addressrange"
                        style={{ marginBottom: '0px' }}
                        data-nmui-intercom="network-details-form_addressrange"
                      >
                        <Input placeholder={t('nhXdTz69d7fCmFnZn7Tdz')} />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            {/* ipv6 */}
            <Row
              style={{
                border: `1px solid ${themeToken.colorBorder}`,
                borderRadius: '8px',
                padding: '.5rem',
                marginBottom: '1.5rem',
              }}
            >
              <Col xs={24}>
                <Row justify="space-between" style={{ marginBottom: isIpv6Watch ? '.5rem' : '0px' }}>
                  <Col>IPv6</Col>
                  <Col>
                    <Form.Item
                      name="isipv6"
                      valuePropName="checked"
                      style={{ marginBottom: '0px' }}
                      data-nmui-intercom="network-details-form_isipv6"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                {isIpv6Watch && (
                  <Row>
                    <Col xs={24}>
                      <Form.Item
                        name="addressrange6"
                        style={{ marginBottom: '0px' }}
                        data-nmui-intercom="network-details-form_addressrange6"
                      >
                        <Input placeholder={t('xq6znd6ph7T0qx4Jf5EdI')} />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row
              style={{
                border: `1px solid ${themeToken.colorBorder}`,
                borderRadius: '8px',
                padding: '.5rem',
                marginBottom: '1.5rem',
              }}
            >
              <Col xs={24}>
                <Row justify="space-between">
                  <Col>{t('hZ8l9wxJyVyfaj1n3qJb1')}</Col>
                  <Col xs={8}>
                    <Form.Item
                      name="defaultacl"
                      style={{ marginBottom: '0px' }}
                      rules={[{ required: true }]}
                      data-nmui-intercom="network-details-form_defaultacl"
                    >
                      <Select
                        size="small"
                        style={{ width: '100%' }}
                        options={[
                          { label: t('yjkR3Dh1NDcvKl8Kqjbs'), value: 'yes' },
                          { label: t('mVOj86bNSxyA0bU0RkgCg'), value: 'no' },
                        ]}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }, [network, form, isEditingNetwork, themeToken.colorBorder, isIpv4Watch, isIpv6Watch]);

  const getHostsContent = useCallback(() => {
    return (
      <div className="" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Row justify="space-between" style={{ marginBottom: '1rem', width: '100%' }}>
          <Col xs={12} md={8}>
            <Input
              size="large"
              placeholder={t('zBovaEd8cVaaz5Hcj0KS')}
              value={searchHost}
              onChange={(ev) => setSearchHost(ev.target.value)}
              prefix={<SearchOutlined />}
            />
          </Col>
          <Col xs={12} md={6} style={{ textAlign: 'right' }}>
            <Dropdown.Button
              type="primary"
              style={{ justifyContent: 'end' }}
              icon={<DownOutlined />}
              menu={{
                items: [
                  {
                    key: 'existing-host',
                    label: t('go10d3ZvrH96Rs5R_8Yh'),
                    onClick() {
                      setIsAddHostsToNetworkModalOpen(true);
                    },
                  },
                ],
              }}
              onClick={() => setIsAddNewHostModalOpen(true)}
            >
              <PlusOutlined /> {t('2Qn6bYqM3XbsQ7TlYr7U')}
            </Dropdown.Button>
          </Col>

          <Col xs={24} style={{ paddingTop: '1rem' }}>
            <Table
              columns={[
                {
                  title: t('nPNtKIiBhSEnsfgxsp46'),
                  render: (_, node) => {
                    const hostName = getExtendedNode(node, store.hostsCommonDetails).name;
                    return (
                      <>
                        <Link to={getNetworkHostRoute(node.hostid, node.network)}>{hostName}</Link>
                        {node.pendingdelete && (
                          <Badge style={{ marginLeft: '1rem' }} status="processing" color="red" text="Removing..." />
                        )}
                      </>
                    );
                  },
                  sorter: (a, b) => {
                    const hostNameA = getExtendedNode(a, store.hostsCommonDetails).name;
                    const hostNameB = getExtendedNode(b, store.hostsCommonDetails).name;
                    return hostNameA?.localeCompare(hostNameB ?? '') ?? 0;
                  },
                  defaultSortOrder: 'ascend',
                },
                {
                  title: t('cdnesIOz73hAyJkgW_3Oj'),
                  dataIndex: 'address',
                },
                network?.isipv6
                  ? {
                      title: t('cvSiaDhm1evib55Qk7qS'),
                      dataIndex: 'address6',
                    }
                  : {},
                {
                  title: t('wnOlnc2jNmFw9b8O2Smc'),
                  render(_, node) {
                    return getExtendedNode(node, store.hostsCommonDetails)?.endpointip ?? '';
                  },
                },
                // {
                //   title: 'Preferred DNS',
                //   dataIndex: 'name',
                // },
                {
                  title: t('5EUqeedZcWm5yj0dotI'),
                  render: (_, node) => (
                    <Tag color={node.connected ? 'green' : 'red'}>{node.connected ? 'Connected' : 'Disconnected'}</Tag>
                  ),
                },
                {
                  title: t('7fY9K7Aioj5MpMEtc9Ni1'),
                  render(_, node) {
                    return getHostHealth(node.hostid, [node]);
                  },
                },
                {
                  width: '1rem',
                  align: 'right',
                  render(_: boolean, node) {
                    return (
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: 'edit',
                              label: t('giSlJxeyxvkwuaiGyFDji'),
                              disabled: node.pendingdelete !== false,
                              title: node.pendingdelete !== false ? t('755601zJzeKrqqdVYdG42') : '',
                              onClick: () => editNode(node),
                            },
                            {
                              key: 'disconnect',
                              label: node.connected ? t('d9Crx3DnXPcK32WLnZ8k') : t('elr1666gaZ7UgA5M0_11W'),
                              disabled: node.pendingdelete !== false,
                              title: node.pendingdelete !== false ? t('z3vcbM40mZNzcFnLQoyCb') : '',
                              onClick: () =>
                                disconnectNodeFromNetwork(
                                  !node.connected,
                                  getExtendedNode(node, store.hostsCommonDetails),
                                ),
                            },
                            {
                              key: 'remove',
                              label: t('t4D4CzDaaUztt1sqOg78G'),
                              danger: true,
                              disabled: node.pendingdelete !== false,
                              title: node.pendingdelete !== false ? t('755601zJzeKrqqdVYdG42') : '',
                              onClick: () =>
                                removeNodeFromNetwork(false, getExtendedNode(node, store.hostsCommonDetails)),
                            },
                          ],
                        }}
                      >
                        <MoreOutlined />
                      </Dropdown>
                    );
                  },
                },
              ]}
              dataSource={networkNodes}
              rowKey="id"
              size="small"
            />
          </Col>
        </Row>
      </div>
    );
  }, [
    searchHost,
    network?.isipv6,
    networkNodes,
    store.hostsCommonDetails,
    editNode,
    disconnectNodeFromNetwork,
    removeNodeFromNetwork,
  ]);

  const getDnsContent = useCallback(() => {
    return (
      <div className="" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Row justify="space-between" style={{ marginBottom: '1rem', width: '100%' }}>
          <Col xs={12} md={8}>
            <Input
              size="large"
              placeholder={t('cF5c3lpIbCEtL21uX2ft')}
              value={searchDns}
              onChange={(ev) => setSearchDns(ev.target.value)}
              prefix={<SearchOutlined />}
            />
          </Col>
          <Col xs={12} md={6} style={{ textAlign: 'right' }}>
            <Button type="primary" size="large" onClick={() => setIsAddDnsModalOpen(true)}>
              <PlusOutlined /> {t('s9ps1idGvtEx4ZoWyjBt5')}
            </Button>
          </Col>

          <Col xs={24} style={{ paddingTop: '1rem' }}>
            <Table
              columns={[
                {
                  title: t('9fCE8Cb8Xu6oawEGbi1o'),
                  render(_, dns) {
                    return <Typography.Text copyable>{`${dns.name}.${dns.network}`}</Typography.Text>;
                  },
                  sorter: (a, b) => a.name.localeCompare(b.name),
                  defaultSortOrder: 'ascend',
                },
                {
                  title: t('bT0xUXb4mCZhZLlfnwQg'),
                  render(_, dns) {
                    return (
                      <Typography.Text copyable>{[dns.address].concat(dns.address6 || []).join(', ')}</Typography.Text>
                    );
                  },
                },
                {
                  title: '',
                  key: 'action',
                  width: '1rem',
                  render: (_, dns) => (
                    <Dropdown
                      placement="bottomRight"
                      menu={{
                        items: [
                          {
                            key: 'delete',
                            disabled: isDefaultDns(dns),
                            label: (
                              <Tooltip
                                title={isDefaultDns(dns) ? t('lw0PsK1hLd9WnhfLhLvrg') : t('glD1kIeQkXcRdVeXCyVn')}
                              >
                                <Typography.Text
                                  disabled={isDefaultDns(dns)}
                                  onClick={() => (isDefaultDns(dns) ? undefined : confirmDeleteDns(dns))}
                                >
                                  <DeleteOutlined /> {t('u7MiYLt4iCgCJmjgCy8x2')}
                                </Typography.Text>
                              </Tooltip>
                            ),
                          },
                        ] as MenuProps['items'],
                      }}
                    >
                      <MoreOutlined />
                    </Dropdown>
                  ),
                },
              ]}
              dataSource={dnses.filter((dns) => dns.name.toLocaleLowerCase().includes(searchDns.toLocaleLowerCase()))}
              rowKey="name"
              size="small"
            />
          </Col>
        </Row>
      </div>
    );
  }, [confirmDeleteDns, dnses, isDefaultDns, searchDns]);

  const getClientsContent = useCallback(() => {
    const isEmpty = clients.length === 0 && clientGateways.length === 0;

    return (
      <div className="" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {isEmpty && (
          <Row
            className="page-padding"
            style={{
              background: 'linear-gradient(90deg, #52379F 0%, #B66666 100%)',
              width: '100%',
            }}
          >
            <Col xs={(24 * 2) / 3}>
              <Typography.Title level={3} style={{ color: 'white ' }}>
                {t('uUZvfgJMfdExcyCw9AfGc')}
              </Typography.Title>
              <Typography.Text style={{ color: 'white ' }}>
                {t('70CsnCRabc9Ut9KzUqB')}{' '}
                {/* <a href="https://www.netmaker.io/features/ingress" target="_blank" rel="noreferrer"> */}
                <a href="#" target="_blank" rel="noreferrer">
                  {/* {t('tVfoRrjn1j2jh2c8Tk_5P')} */}
                </a>
              </Typography.Text>
            </Col>
            <Col xs={(24 * 1) / 3} style={{ position: 'relative' }}>
              <Card className="header-card" style={{ position: 'absolute', width: '100%' }}>
                <Typography.Title level={3}>{t('n9xZ3qP5GEaVusxrbY6bk')}</Typography.Title>
                <Typography.Text>
                  {t('vos70X1rBWamYmWdtCfQm')}{' '}
                  <a href="https://www.wireguard.com/install" target="_blank" rel="noreferrer">
                    {/* {t('eq2cQiHn7EHvFp3ECbb2')} */}
                  </a>
                  {/* {t('vL0Dm5UqycQrpoNydkaAz')} */}
                </Typography.Text>
                {clientGateways.length === 0 && (
                  <Alert
                    type="warning"
                    showIcon
                    message={t('i9aNvYPn7VqiPw9uv7Zbm')}
                    description={t('eUbvIXwcgaz0Hi8EvoMjP')}
                    style={{ marginTop: '1rem' }}
                  />
                )}
                <Row style={{ marginTop: '1rem' }}>
                  <Col>
                    <Button type="primary" size="large" onClick={() => setIsAddClientModalOpen(true)}>
                      <PlusOutlined /> {t('n9xZ3qP5GEaVusxrbY6bk')}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}

        {!isEmpty && (
          <Row style={{ width: '100%' }}>
            <Col xs={12} style={{ marginBottom: '2rem' }}>
              <Input
                placeholder={t('aRy1Apzjg33VpwqtfMkw6')}
                value={searchClientGateways}
                onChange={(ev) => setSearchClientGateways(ev.target.value)}
                prefix={<SearchOutlined />}
                style={{ width: '60%' }}
              />
            </Col>
            <Col xs={12} style={{ marginBottom: '2rem' }}>
              <Input
                placeholder={t('moT02dZr01qPSyMYhS23f')}
                value={searchClients}
                onChange={(ev) => setSearchClients(ev.target.value)}
                prefix={<SearchOutlined />}
                style={{ width: '60%' }}
              />
            </Col>
            <Col xs={12}>
              <Row style={{ width: '100%' }}>
                <Col xs={12}>
                  <Typography.Title style={{ marginTop: '0px' }} level={5}>
                    {t('o0fpkP1XeP5Ktqj32px4p')}
                  </Typography.Title>
                </Col>
                <Col xs={11} style={{ textAlign: 'right' }}>
                  <Button type="primary" onClick={() => setIsAddClientGatewayModalOpen(true)}>
                    <PlusOutlined /> {t('ofyTeaij5MJoLguK4bfL')}
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: '1rem' }}>
                <Col xs={23}>
                  <Table
                    columns={gatewaysTableCols}
                    dataSource={filteredClientGateways}
                    rowKey="id"
                    size="small"
                    rowClassName={(gateway) => {
                      return gateway.id === selectedGateway?.id ? 'selected-row' : '';
                    }}
                    onRow={(gateway) => {
                      return {
                        onClick: () => {
                          if (selectedGateway?.id === gateway.id) setSelectedGateway(null);
                          else setSelectedGateway(gateway);
                        },
                      };
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row style={{ width: '100%' }}>
                <Col xs={12}>
                  <Typography.Title style={{ marginTop: '0px' }} level={5}>
                    {t('uUZvfgJMfdExcyCw9AfGc')}
                  </Typography.Title>
                </Col>
                <Col xs={12} style={{ textAlign: 'right' }}>
                  {selectedGateway && (
                    <Button
                      type="primary"
                      style={{ marginRight: '1rem' }}
                      onClick={() => setIsAddClientModalOpen(true)}
                    >
                      <PlusOutlined /> {t('n9xZ3qP5GEaVusxrbY6bk')}
                    </Button>
                  )}
                  {t('h0xo77ttg8gt5SPjxpUun')}{' '}
                  <Switch
                    title={t('gu4ard25krBuIcMwKtkKe')}
                    checked={selectedGateway === null}
                    onClick={() => {
                      setSelectedGateway(null);
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: '1rem' }}>
                <Col xs={24}>
                  <Table columns={clientsTableCols} dataSource={filteredClients} rowKey="clientid" size="small" />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    );
  }, [
    clients.length,
    clientGateways.length,
    searchClientGateways,
    searchClients,
    gatewaysTableCols,
    filteredClientGateways,
    selectedGateway,
    clientsTableCols,
    filteredClients,
  ]);

  const getEgressContent = useCallback(() => {
    return (
      <div className="" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {egresses.length === 0 && (
          <Row
            className="page-padding"
            style={{
              background: 'linear-gradient(90deg, #52379F 0%, #B66666 100%)',
              width: '100%',
            }}
          >
            <Col xs={16}>
              <Typography.Title level={3} style={{ color: 'white ' }}>
                {t('jxEJdmYve7lNvhpnQlnP')}
              </Typography.Title>
              <Typography.Text style={{ color: 'white ' }}>
                {t('yDh9rOy0ryJd8WapP6')}{' '}
                <a href="https://www.netmaker.io/features/egress" target="_blank" rel="noreferrer">
                  {/* {t('gl2A0hfoWzw2kOYi1LR')} */}
                </a>
                .
              </Typography.Text>
            </Col>
            <Col xs={8} style={{ position: 'relative' }}>
              <Card className="header-card" style={{ position: 'absolute', width: '100%' }}>
                <Typography.Title level={3}>{t('e3tuyuIt3ds4wPgVdB0Go')}</Typography.Title>
                <Typography.Text>{t('b8H6XvTiAUcmEt5ey3Brq')}</Typography.Text>
                <Row style={{ marginTop: '5rem' }}>
                  <Col>
                    <Button type="primary" size="large" onClick={() => setIsAddEgressModalOpen(true)}>
                      <PlusOutlined /> {t('e3tuyuIt3ds4wPgVdB0Go')}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}

        {egresses.length > 0 && (
          <Row style={{ width: '100%' }}>
            <Col xs={24} style={{ marginBottom: '2rem' }}>
              <Input
                placeholder="Search egress"
                value={searchEgress}
                onChange={(ev) => setSearchEgress(ev.target.value)}
                prefix={<SearchOutlined />}
                style={{ width: '30%' }}
              />
            </Col>
            <Col xs={12}>
              <Row style={{ width: '100%' }}>
                <Col xs={12}>
                  <Typography.Title style={{ marginTop: '0px' }} level={5}>
                    {t('sfk5dCEhQl25tUAk8j73')}
                  </Typography.Title>
                </Col>
                <Col xs={11} style={{ textAlign: 'right' }}>
                  <Button type="primary" onClick={() => setIsAddEgressModalOpen(true)}>
                    <PlusOutlined /> {t('e3tuyuIt3ds4wPgVdB0Go')}
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: '1rem' }}>
                <Col xs={23}>
                  <Table
                    columns={egressTableCols}
                    dataSource={filteredEgresses}
                    rowKey="id"
                    size="small"
                    rowClassName={(egress) => {
                      return egress.id === filteredEgress?.id ? 'selected-row' : '';
                    }}
                    onRow={(egress) => {
                      return {
                        onClick: () => {
                          if (filteredEgress?.id === egress.id) setFilteredEgress(null);
                          else setFilteredEgress(egress);
                        },
                      };
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row style={{ width: '100%' }}>
                <Col xs={12}>
                  <Typography.Title style={{ marginTop: '0px' }} level={5}>
                    {t('h8Vs3wtRgarsTUk89aZam')}
                  </Typography.Title>
                </Col>
                <Col xs={12} style={{ textAlign: 'right' }}>
                  {filteredEgress && (
                    <Button
                      type="primary"
                      style={{ marginRight: '1rem' }}
                      onClick={() => setIsUpdateEgressModalOpen(true)}
                    >
                      <PlusOutlined /> {t('ceXqxVmKaRGacSkZhh2M')}
                    </Button>
                  )}
                  {t('h0xo77ttg8gt5SPjxpUun')}{' '}
                  <Switch
                    title={t('gIc49Sf5HsbJymsucMqz')}
                    checked={filteredEgress === null}
                    onClick={() => {
                      setFilteredEgress(null);
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: '1rem' }}>
                <Col xs={24}>
                  <Table
                    columns={externalRoutesTableCols}
                    dataSource={filteredExternalRoutes}
                    rowKey={(range) => `${range.node?.name ?? ''}-${range.range}`}
                    size="small"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    );
  }, [
    egresses,
    searchEgress,
    egressTableCols,
    filteredEgresses,
    filteredEgress,
    externalRoutesTableCols,
    filteredExternalRoutes,
  ]);

  const getRelayContent = useCallback(() => {
    return (
      <div className="" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {relays.length === 0 && (
          <Row
            className="page-padding"
            style={{
              background: 'linear-gradient(90deg, #52379F 0%, #B66666 100%)',
              width: '100%',
            }}
          >
            <Col xs={16}>
              <Typography.Title level={3} style={{ color: 'white ' }}>
                {t('42fH_9wTvBNaQtIqqjm2C')}
              </Typography.Title>
              <Typography.Text style={{ color: 'white ' }}>
                {t('vbK0w0zrGkZTrk8vecDa')}{' '}
                {t('yqeQk6v7KuXkhG51vKBet', { productName: getBrandingConfig().productName })}
                <a href="https://www.netmaker.io/features/relay" target="_blank" rel="noreferrer">
                  {/* {t('nwxm4Yly1Bci4Kaf92Inw')} */}
                </a>
                .
              </Typography.Text>
            </Col>
            <Col xs={8} style={{ position: 'relative' }}>
              <Card className="header-card" style={{ position: 'absolute', width: '100%' }}>
                <Typography.Title level={3}>{t('2lvOVf6Jh1_7aQElGrYLb')}</Typography.Title>
                <Typography.Text>{t('wx7CVo8cNt17Q6E0ZFb7s')}</Typography.Text>
                <Row style={{ marginTop: '5rem' }}>
                  <Col>
                    <Button type="primary" size="large" onClick={() => setIsAddRelayModalOpen(true)}>
                      <PlusOutlined /> {t('2lvOVf6Jh1_7aQElGrYLb')}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}

        {relays.length > 0 && (
          <Row style={{ width: '100%' }}>
            <Col xs={24} style={{ marginBottom: '2rem' }}>
              <Input
                placeholder={t('6JVr9lS5CsgPlmNKoYby')}
                value={searchRelay}
                onChange={(ev) => setSearchRelay(ev.target.value)}
                prefix={<SearchOutlined />}
                style={{ width: '30%' }}
              />
            </Col>
            <Col xs={12}>
              <Row style={{ width: '100%' }}>
                <Col xs={12}>
                  <Typography.Title style={{ marginTop: '0px' }} level={5}>
                    {t('42fH_9wTvBNaQtIqqjm2C')}
                  </Typography.Title>
                </Col>
                <Col xs={11} style={{ textAlign: 'right' }}>
                  <Button type="primary" onClick={() => setIsAddRelayModalOpen(true)}>
                    <PlusOutlined /> {t('2lvOVf6Jh1_7aQElGrYLb')}
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: '1rem' }}>
                <Col xs={23}>
                  <Table
                    columns={relayTableCols}
                    dataSource={filteredRelays}
                    rowKey="id"
                    size="small"
                    rowClassName={(relay) => {
                      return relay.id === selectedRelay?.id ? 'selected-row' : '';
                    }}
                    onRow={(relay) => {
                      return {
                        onClick: () => {
                          if (selectedRelay?.id === relay.id) setSelectedRelay(null);
                          else setSelectedRelay(relay);
                        },
                      };
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row style={{ width: '100%' }}>
                <Col xs={12}>
                  <Typography.Title style={{ marginTop: '0px' }} level={5}>
                    {t('o2J6UwZun9Wng2DWhfnjl')}
                  </Typography.Title>
                </Col>
                <Col xs={12} style={{ textAlign: 'right' }}>
                  {selectedRelay && (
                    <Button
                      type="primary"
                      style={{ marginRight: '1rem' }}
                      onClick={() => setIsUpdateRelayModalOpen(true)}
                    >
                      <PlusOutlined /> Add relayed host
                    </Button>
                  )}
                  {t('h0xo77ttg8gt5SPjxpUun')}{' '}
                  <Switch
                    title={t('jk7EoECaIdrCqEtl3Dw')}
                    checked={selectedRelay === null}
                    onClick={() => {
                      setSelectedRelay(null);
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: '1rem' }}>
                <Col xs={24}>
                  <Table columns={relayedTableCols} dataSource={filteredRelayedNodes} rowKey="id" size="small" />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    );
  }, [filteredRelayedNodes, filteredRelays, relayTableCols, relayedTableCols, relays, searchRelay, selectedRelay]);

  const getAclsContent = useCallback(() => {
    return (
      <div className="" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Row style={{ width: '100%' }}>
          <Col xs={12}>
            <Input
              allowClear
              placeholder={t('wBh4zLzi1T5PZ0cMtk')}
              value={searchAclHost}
              onChange={(ev) => setSearchAclHost(ev.target.value)}
              prefix={<SearchOutlined />}
              style={{ width: '60%' }}
            />
            {isServerEE && (
              <span style={{ marginLeft: '2rem' }}>
                <label style={{ marginRight: '1rem' }} htmlFor="show-clients-acl-switch">
                  {t('xtZm4sV9pNndwtdUyvdwW')}
                </label>
                <Switch
                  id="show-clients-acl-switch"
                  checked={showClientAcls}
                  onChange={(newVal) => setShowClientAcls(newVal)}
                />
              </span>
            )}
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button
              title={t('gAffpn7b2Os3EazWccmkL')}
              style={{ marginRight: '1rem', color: '#3C8618', borderColor: '#274916' }}
              icon={<CheckOutlined />}
              onClick={() => {
                // set node acls
                setNodeAcls((prevAcls) => {
                  const newAcls = structuredClone(prevAcls);
                  for (const nodeId1 in newAcls) {
                    if (Object.prototype.hasOwnProperty.call(newAcls, nodeId1)) {
                      const nodeAcl = newAcls[nodeId1];
                      for (const nodeId in nodeAcl) {
                        if (Object.prototype.hasOwnProperty.call(nodeAcl, nodeId)) {
                          nodeAcl[nodeId] = 2;
                        }
                      }
                    }
                  }
                  return newAcls;
                });

                // set client acls
                setClientAcls((prevAcls) => {
                  const newAcls = structuredClone(prevAcls);
                  for (const clientId in newAcls) {
                    if (Object.prototype.hasOwnProperty.call(newAcls, clientId)) {
                      newAcls[clientId] = {};
                    }
                  }
                  return newAcls;
                });
              }}
            />
            <Button
              danger
              title={t('nkrWmTq5bZl6rQ5MqrSlK')}
              style={{ marginRight: '1rem' }}
              icon={<StopOutlined />}
              onClick={() => {
                // set node acls
                setNodeAcls((prevAcls) => {
                  const newAcls = structuredClone(prevAcls);
                  for (const nodeId1 in newAcls) {
                    if (Object.prototype.hasOwnProperty.call(newAcls, nodeId1)) {
                      const nodeAcl = newAcls[nodeId1];
                      for (const nodeId in nodeAcl) {
                        if (Object.prototype.hasOwnProperty.call(nodeAcl, nodeId)) {
                          nodeAcl[nodeId] = 1;
                        }
                      }
                    }
                  }
                  return newAcls;
                });

                // set client acls
                setClientAcls((prevAcls) => {
                  const newAcls = structuredClone(prevAcls);
                  for (const clientId in newAcls) {
                    if (Object.prototype.hasOwnProperty.call(newAcls, clientId)) {
                      newAcls[clientId] = {};

                      clients.forEach((c) => {
                        newAcls[clientId][c.clientid] = {} as never;
                      });

                      networkNodes.forEach((n) => {
                        newAcls[clientId][n.id] = {} as never;
                      });
                    }
                  }
                  return newAcls;
                });
              }}
            />
            <Button
              title={t('dw39ylExaSkQjV2Hif5I9')}
              style={{ marginRight: '1rem' }}
              icon={<ReloadOutlined />}
              onClick={() => {
                setNodeAcls(originalNodeAcls);
                setClientAcls(originalClientAcls);
              }}
              disabled={!hasAclsBeenEdited}
            />
            <Button
              type="primary"
              onClick={async () => {
                try {
                  if (!networkId) return;
                  setIsSubmittingAcls(true);
                  const newAcls = (await NetworksService.updateAcls(networkId, nodeAcls)).data;
                  if (isServerEE) await updateAllClientsAcls();
                  setOriginalNodeAcls(newAcls);
                  setNodeAcls(newAcls);
                  notify.success({
                    message: t('t4cQhJxkYOzI3r3Ufw7Rw'),
                  });
                } catch (err) {
                  notify.error({
                    message: t('nGvam98t01_0cLd8eEyl3'),
                    description: extractErrorMsg(err as any),
                  });
                } finally {
                  setIsSubmittingAcls(false);
                }
              }}
              disabled={!hasAclsBeenEdited}
              loading={isSubmittingAcls}
            >
              {t('iUgtwG9ydIuj9czcEQku')}
            </Button>
          </Col>

          <Col xs={24} style={{ paddingTop: '1rem' }}>
            <div className="" style={{ width: '100%', overflow: 'auto' }}>
              <VirtualisedTable
                columns={aclTableCols}
                dataSource={filteredAclData}
                className="acl-table"
                rowKey="nodeOrClientId"
                size="small"
                pagination={false}
                scroll={{
                  x: '100%',
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }, [
    searchAclHost,
    showClientAcls,
    hasAclsBeenEdited,
    isSubmittingAcls,
    aclTableCols,
    filteredAclData,
    clients,
    networkNodes,
    originalNodeAcls,
    originalClientAcls,
    networkId,
    nodeAcls,
    isServerEE,
    updateAllClientsAcls,
    notify,
  ]);

  const getGraphContent = useCallback(() => {
    const containerHeight = '78vh';

    if (!network) {
      return (
        <div
          className=""
          style={{
            width: '100%',
            height: containerHeight,
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <LoadingOutlined style={{ fontSize: '5rem' }} spin />
        </div>
      );
    }

    return (
      <div className="" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Row style={{ width: '100%' }}>
          <Col xs={24} style={{ width: '100%', height: containerHeight }}>
            <SigmaContainer
              id={NETWORK_GRAPH_SIGMA_CONTAINER_ID}
              style={{
                backgroundColor: themeToken.colorBgContainer,
                position: 'relative',
              }}
            >
              <NetworkGraph
                network={network}
                hosts={networkHosts}
                nodes={networkNodes}
                acl={networkAcls}
                clients={clients}
              />
              <ControlsContainer position={'top-left'}>
                <ZoomControl />
                <FullScreenControl />
              </ControlsContainer>
              <ControlsContainer position={'top-left'} className="search-container">
                <SearchControl />
              </ControlsContainer>
            </SigmaContainer>
          </Col>
        </Row>
      </div>
    );
  }, [clients, network, networkAcls, networkHosts, networkNodes, themeToken.colorBgContainer]);

  const getMetricsContent = useCallback(() => {
    return (
      <div className="" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Row style={{ width: '100%' }}>
          <Col xs={16}>
            <Radio.Group value={currentMetric} onChange={(ev) => setCurrentMetric(ev.target.value)}>
              <Radio.Button value="connectivity-status">{t('d3fSh0Y6WpC8Pb0Bkqmt')}</Radio.Button>
              <Radio.Button value="latency">{t('gD75aW9Te9enTXpPxPkvx')}</Radio.Button>
              <Radio.Button value="bytes-sent">{t('g7_7_67TPoQ5p12OVsAk2')}</Radio.Button>
              <Radio.Button value="bytes-received">{t('rY2wtVmWfYyrSquSLlQhk')}</Radio.Button>
              <Radio.Button value="uptime">{t('cwqPu_3Tb5wRgmh1mCg5J')}</Radio.Button>
              <Radio.Button value="clients">{t('uUZvfgJMfdExcyCw9AfGc')}</Radio.Button>
            </Radio.Group>
          </Col>
          <Col xs={8} style={{ textAlign: 'right' }}>
            {/* <Button type="primary" loading={isDownloadingMetrics} onClick={() => downloadMetrics()}>
              <DownloadOutlined />
              Download Metrics
            </Button> */}
          </Col>

          <Col xs={24} style={{ paddingTop: '1rem' }}>
            <div className="" style={{ width: '100%', overflow: 'auto' }}>
              {currentMetric === 'connectivity-status' && (
                <Table
                  columns={metricsTableCols}
                  dataSource={connectivityStatusMetricsData}
                  className="connectivity-status-metrics-table"
                  rowKey="nodeId"
                  size="small"
                  pagination={false}
                />
              )}
              {currentMetric === 'latency' && (
                <Table
                  columns={metricsTableCols}
                  dataSource={latencyMetricsData}
                  className="latency-metrics-table"
                  rowKey="nodeId"
                  size="small"
                  pagination={false}
                />
              )}
              {currentMetric === 'bytes-sent' && (
                <Table
                  columns={metricsTableCols}
                  dataSource={bytesSentMetricsData}
                  className="bytes-sent-metrics-table"
                  rowKey="nodeId"
                  size="small"
                  pagination={false}
                />
              )}
              {currentMetric === 'bytes-received' && (
                <Table
                  columns={metricsTableCols}
                  dataSource={bytesReceivedMetricsData}
                  className="bytes-received-metrics-table"
                  rowKey="nodeId"
                  size="small"
                  pagination={false}
                />
              )}
              {currentMetric === 'uptime' && (
                <Table
                  columns={metricsTableCols}
                  dataSource={latencyMetricsData}
                  className="latency-metrics-table"
                  rowKey="nodeId"
                  size="small"
                  pagination={false}
                />
              )}
              {currentMetric === 'clients' && (
                <Table
                  columns={clientMetricsTableCols}
                  dataSource={clientsMetricsData}
                  className="clients-metrics-table"
                  rowKey="node_name"
                  size="small"
                  pagination={false}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }, [
    currentMetric,
    metricsTableCols,
    connectivityStatusMetricsData,
    latencyMetricsData,
    bytesSentMetricsData,
    bytesReceivedMetricsData,
    clientMetricsTableCols,
    clientsMetricsData,
    // isDownloadingMetrics,
    // downloadMetrics,
  ]);

  const networkTabs: TabsProps['items'] = useMemo(() => {
    const tabs = [
      {
        key: 'overview',
        label: t('5sQKidSqfNcKtwButDpLe'),
        children: network ? getOverviewContent() : <Skeleton active />,
      },
      {
        key: 'hosts',
        label: t('q0XSfVSnvlHzkjquxRUxh', { length: networkHosts.length }),
        children: network ? getHostsContent() : <Skeleton active />,
      },
      {
        key: 'clients',
        label: t('l2MfCg4HsoBMjl0z8E7q', { length: clients.length }),
        children: network ? getClientsContent() : <Skeleton active />,
      },
      {
        key: 'egress',
        label: t('1AgIeSqRv2licEFqwFtFl', { length: egresses.length }),
        children: network ? getEgressContent() : <Skeleton active />,
      },
      {
        key: 'dns',
        label: t('x5wYvHmOiFfgOnMAycMEc'),
        children: network ? getDnsContent() : <Skeleton active />,
      },
      {
        key: 'access-control',
        label: t('osemBlNqico62di9dnGSt'),
        children: network ? getAclsContent() : <Skeleton active />,
      },
      {
        key: 'graph',
        label: t('hgiTeC8v0WmLvmnjGr4B'),
        children: network ? getGraphContent() : <Skeleton active />,
      },
    ].concat(
      isServerEE
        ? [
            {
              key: 'metrics',
              label: t('pytwe1wHyeCx5LnR069vu'),
              children: network ? getMetricsContent() : <Skeleton active />,
            },
          ]
        : [],
    );

    if (isServerEE) {
      tabs.splice(3, 0, {
        key: 'relays',
        label: t('17yjetLxuKIEff5gA4Xk', { length: relays.length }),
        children: network ? getRelayContent() : <Skeleton active />,
      });
    }

    return tabs;
  }, [
    network,
    getOverviewContent,
    networkHosts.length,
    getHostsContent,
    clients.length,
    getClientsContent,
    egresses.length,
    getEgressContent,
    relays.length,
    getRelayContent,
    getDnsContent,
    getAclsContent,
    getGraphContent,
    isServerEE,
    getMetricsContent,
  ]);

  const loadDnses = useCallback(async () => {
    try {
      if (!networkId) return;
      const dnses = (await NetworksService.getDnses()).data;
      const networkDnses = dnses.filter((dns) => dns.network === networkId);
      setDnses(networkDnses);
    } catch (err) {
      if (err instanceof AxiosError) {
        notify.error({
          message: t('twZfIPmAyIjxuAr74g4I'),
          description: extractErrorMsg(err),
        });
      }
    }
  }, [networkId, notify]);

  const loadMetrics = useCallback(async () => {
    try {
      if (!networkId) return;
      const nodeMetrics = (await NetworksService.getNodeMetrics(networkId)).data;
      setNetworkNodeMetrics(nodeMetrics);
      const clientMetrics = (await NetworksService.getClientMetrics(networkId)).data ?? {};
      setClientMetrics(clientMetrics);
    } catch (err) {
      notify.error({
        message: t('wgtcNair_6Wngxo7s3af'),
        description: extractErrorMsg(err as any),
      });
    }
  }, [networkId, notify]);

  const loadNetwork = useCallback(() => {
    setIsLoading(true);
    // route to networks if id is not present
    if (!networkId) {
      navigate(AppRoutes.NETWORKS_ROUTE);
    }
    // load from store
    const network = store.networks.find((network) => network.netid === networkId);
    if (!network) {
      notify.error({ message: t('aq37hixQYiXfMebfRFqb', { networkId }) });
      navigate(AppRoutes.NETWORKS_ROUTE);
      return;
    }
    setNetwork(network);

    // load extra data
    loadDnses();
    loadAcls();
    loadClients();

    if (isServerEE) {
      loadMetrics();
    }

    setIsLoading(false);
  }, [networkId, store.networks, loadDnses, loadAcls, loadClients, isServerEE, navigate, notify, loadMetrics]);

  // const onNetworkFormEdit = useCallback(async () => {
  //   try {
  //     const formData = await form.validateFields();
  //     const network = store.networks.find((network) => network.netid === networkId);
  //     if (!networkId || !network) {
  //       throw new Error('Network not found');
  //     }
  //     const newNetwork = (
  //       await NetworksService.updateNetwork(networkId, convertUiNetworkToNetworkPayload({ ...network, ...formData }))
  //     ).data;
  //     store.updateNetwork(networkId, convertNetworkPayloadToUiNetwork(newNetwork));
  //     notify.success({ message: `Network ${networkId} updated` });
  //     setIsEditingNetwork(false);
  //   } catch (err) {
  //     if (err instanceof AxiosError) {
  //       notify.error({
  //         message: 'Failed to save changes',
  //         description: extractErrorMsg(err),
  //       });
  //     } else {
  //       notify.error({
  //         message: err instanceof Error ? err.message : 'Failed to save changes',
  //       });
  //     }
  //   }
  // }, [form, networkId, notify, store]);

  const onNetworkDelete = useCallback(async () => {
    try {
      if (!networkId) {
        throw new Error(t('zRA7fSpG8PeSoH3FmOgi'));
      }
      await NetworksService.deleteNetwork(networkId);
      notify.success({ message: t('tbqr9Y6NmLl3RtuCfSGx', { networkId }) });
      store.deleteNetwork(networkId);
      navigate(AppRoutes.NETWORKS_ROUTE);
    } catch (err) {
      if (err instanceof AxiosError) {
        notify.error({
          message: t('e0xqwH2nCo1UBffQiHjy4'),
          description: extractErrorMsg(err),
        });
      } else {
        notify.error({
          message: err instanceof Error ? err.message : t('e0xqwH2nCo1UBffQiHjy4'),
        });
      }
    }
  }, [networkId, notify, navigate, store]);

  const onCreateDns = useCallback((dns: DNS) => {
    setDnses((prevDnses) => [...prevDnses, dns]);
    setIsAddDnsModalOpen(false);
  }, []);

  const promptConfirmDelete = () => {
    Modal.confirm({
      title: t('8lzQEtOBfD1TAgyAzGlL', { netid: network?.netid }),
      icon: <ExclamationCircleFilled />,
      onOk() {
        onNetworkDelete();
      },
      okType: 'danger',
    });
  };

  useEffect(() => {
    loadNetwork();
  }, [loadNetwork]);

  // refresh form to prevent stick network data across different network details pages
  useEffect(() => {
    if (!network) return;
    form.setFieldsValue(network);
  }, [form, network]);

  if (!networkId) {
    navigate(AppRoutes.NETWORKS_ROUTE);
    return null;
  }

  return (
    <Layout.Content
      className="NetworkDetailsPage"
      style={{ position: 'relative', height: '100%', padding: props.isFullScreen ? 0 : 24 }}
      key={networkId}
    >
      <Skeleton loading={isLoading} active className="page-padding">
        {/* top bar */}
        <Row className="tabbed-page-row-padding">
          <Col xs={24}>
            <Link to={AppRoutes.NETWORKS_ROUTE}>{t('yo0DAvaOjyy5vH3QuPj8i')}</Link>
            <Row>
              <Col xs={18}>
                <Typography.Title level={2} style={{ marginTop: '.5rem', marginBottom: '2rem' }}>
                  {network?.netid}
                </Typography.Title>
              </Col>
              <Col xs={6} style={{ textAlign: 'right' }}>
                {/* {!isEditingNetwork && (
                  <Button type="default" style={{ marginRight: '.5rem' }} onClick={() => setIsEditingNetwork(true)}>
                    Edit
                  </Button>
                )}
                {isEditingNetwork && (
                  <>
                    <Button type="primary" style={{ marginRight: '.5rem' }} onClick={onNetworkFormEdit}>
                      Save Changes
                    </Button>
                    <Button
                      style={{ marginRight: '.5rem' }}
                      onClick={() => {
                        setIsEditingNetwork(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )} */}
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'delete',
                        label: t('u7MiYLt4iCgCJmjgCy8x2'),
                        danger: true,
                        icon: <DeleteOutlined />,
                        onClick: promptConfirmDelete,
                      },
                    ],
                  }}
                >
                  <Button>
                    <SettingOutlined /> {t('dfSzJf8PrODzAp299cOBs')}
                  </Button>
                </Dropdown>
              </Col>
            </Row>

            <Tabs items={networkTabs} />
          </Col>
        </Row>
      </Skeleton>

      {/* misc */}
      {notifyCtx}
      <AddDnsModal
        isOpen={isAddDnsModalOpen}
        networkId={networkId}
        onCreateDns={onCreateDns}
        onCancel={() => setIsAddDnsModalOpen(false)}
      />
      <AddClientModal
        isOpen={isAddClientModalOpen}
        networkId={networkId}
        preferredGateway={selectedGateway ?? undefined}
        onCreateClient={() => {
          loadClients();
          store.fetchNodes();
          setIsAddClientModalOpen(false);
        }}
        onCancel={() => setIsAddClientModalOpen(false)}
      />
      <AddEgressModal
        isOpen={isAddEgressModalOpen}
        networkId={networkId}
        onCreateEgress={() => {
          store.fetchNodes();
          setIsAddEgressModalOpen(false);
        }}
        onCancel={() => setIsAddEgressModalOpen(false)}
      />
      {targetClient && (
        <ClientDetailsModal
          key={`read-${targetClient.clientid}`}
          isOpen={isClientDetailsModalOpen}
          client={targetClient}
          // onDeleteClient={() => {
          //   loadClients();
          // }}
          onUpdateClient={(updatedClient: ExternalClient) => {
            setClients((prev) => prev.map((c) => (c.clientid === targetClient.clientid ? updatedClient : c)));
            setTargetClient(updatedClient);
          }}
          onCancel={() => setIsClientDetailsModalOpen(false)}
        />
      )}
      {filteredEgress && (
        <UpdateEgressModal
          key={filteredEgress.id}
          isOpen={isUpdateEgressModalOpen}
          networkId={networkId}
          egress={filteredEgress}
          onUpdateEgress={() => {
            store.fetchNodes();
            setIsUpdateEgressModalOpen(false);
          }}
          onCancel={() => setIsUpdateEgressModalOpen(false)}
        />
      )}
      <AddRelayModal
        isOpen={isAddRelayModalOpen}
        networkId={networkId}
        onCreateRelay={() => {
          store.fetchNodes();
          setIsAddRelayModalOpen(false);
        }}
        onCancel={() => setIsAddRelayModalOpen(false)}
      />
      {selectedRelay && (
        <UpdateRelayModal
          key={selectedRelay.id}
          isOpen={isUpdateRelayModalOpen}
          relay={selectedRelay}
          networkId={networkId}
          onUpdateRelay={() => {
            // store.fetchHosts();
            setIsUpdateRelayModalOpen(false);
          }}
          onCancel={() => setIsUpdateRelayModalOpen(false)}
        />
      )}
      <AddHostsToNetworkModal
        isOpen={isAddHostsToNetworkModalOpen}
        networkId={networkId}
        onNetworkUpdated={() => {
          store.fetchNetworks();
          setIsAddHostsToNetworkModalOpen(false);
        }}
        onCancel={() => setIsAddHostsToNetworkModalOpen(false)}
      />
      <NewHostModal
        isOpen={isAddNewHostModalOpen}
        onFinish={() => setIsAddNewHostModalOpen(false)}
        onCancel={() => setIsAddNewHostModalOpen(false)}
      />
      <AddIngressModal
        isOpen={isAddClientGatewayModalOpen}
        networkId={networkId}
        onCreateIngress={() => {
          store.fetchNodes();
          setIsAddClientGatewayModalOpen(false);
        }}
        onCancel={() => setIsAddClientGatewayModalOpen(false)}
      />
      {selectedGateway && (
        <UpdateIngressModal
          isOpen={isUpdateGatewayModalOpen}
          ingress={selectedGateway}
          networkId={networkId}
          onUpdateIngress={() => {
            setIsUpdateGatewayModalOpen(false);
          }}
          onCancel={() => setIsUpdateGatewayModalOpen(false)}
        />
      )}
      {targetClient && (
        <UpdateClientModal
          key={`update-${targetClient.clientid}`}
          isOpen={isUpdateClientModalOpen}
          client={targetClient}
          networkId={networkId}
          onUpdateClient={() => {
            loadClients();
            setIsUpdateClientModalOpen(false);
          }}
          onCancel={() => setIsUpdateClientModalOpen(false)}
        />
      )}
      {targetNode && (
        <UpdateNodeModal
          isOpen={isUpdateNodeModalOpen}
          node={targetNode}
          onUpdateNode={() => {
            store.fetchNodes();
            setIsUpdateNodeModalOpen(false);
          }}
          onCancel={() => setIsUpdateNodeModalOpen(false)}
        />
      )}
    </Layout.Content>
  );
}
